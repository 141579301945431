import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/house-on-sorority-row-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, The House On Sorority Row"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 08</h1>
                    <h2>The House On Sorority Row</h2>
                    <h3>May 29, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-house-on-sorority-row">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:02.83)
                            You&#39;re listening to the Brothers Grimm podcast, presented by the Cinema Suicide Film Society on Substack. I&#39;m Brian White, editor in grief of Cinema Suicide. I&#39;m joined by my cohost and actual brother, Dave White. Dave, how you doing?</p>
                            <p>Dave (00:14.673)
                            It&#39;s pretty good. This is taking a sip there.</p>
                            <p>Bryan White (00:18.534)
                            What do you got? What do you got tonight?</p>
                            <p>Dave (00:20.458)
                            Ah, this is a farmhouse ale with peaches. Hey, you actually can taste the peaches. It is conditioned on peaches. So it&#39;s not actually brewed with peaches.</p>
                            <p>Bryan White (00:25.616)
                            Can you can you taste the peaches?</p>
                            <p>Bryan White (00:33.847)
                            I don&#39;t know what conditioning is. What is conditioning?</p>
                            <p>Dave (00:35.839)
                            It just, it soaks in peaches. Or the peaches soak in the beer, I guess, is the appropriate term.</p>
                            <p>Bryan White (00:38.458)
                            Okay. Gotcha. All right. So we practically grew up in neighborhood video stores. The steady diet of garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and Dave. Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can also find us on Twitter and Instagram at GrimBrosPod. You can also contact us directly at GrimBrosPod at gmail.com with any questions, comments, or suggestions.</p>
                            <p>Bryan White (01:06.286)
                            Do let us know if there&#39;s a movie that you would love. We&#39;d like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. You&#39;d be doing us a favor by leaving us a five-star review on Apple Podcasts. Just wanna get all that out of the way at the top of the show. So, before we get going, here&#39;s a warning. We&#39;re basically gonna talk about this movie from beginning to end, so spoilers to follow. And so now here&#39;s the ceremonial taste. I&#39;m gonna give you a little taste of this here movie we&#39;re watching.</p>
                            <p>Bryan White (02:41.846)
                            That&#39;s right, house on sorority row.</p>
                            <p>Dave (02:44.538)
                            Let me tell you something. I would love to see that movie because that movie is not this movie.</p>
                            <p>Bryan White (02:50.566)
                            Yeah, right, right out of the gate. So that is the original theatrical trailer for this for this movie, which like a lot of movies we do on the show, it I think sort of represents but it really totally misrepresents with the entire movie. I suppose that really doesn&#39;t matter. They&#39;re just trying to get people into the into theaters. But what&#39;s really interesting to me is that the movie itself is very clearly trying to exploit popular slashers at the time.</p>
                            <p>Bryan White (03:18.406)
                            But that trailer makes it look like the girls at the sorority are the murderers, and it&#39;s a really strange way to sell this movie.</p>
                            <p>Dave (03:25.436)
                            I&#39;m not sure that the people who made this movie actually know what it is that they wanted to do. This really feels like it&#39;s maybe two or three ideas kind of cobbled together. And I will say it&#39;s possible that I oversold this in my enthusiasm. Because every time I think about it, I think, God, I love this movie. And then I watch it. And I think, yeah, I kind of like this movie.</p>
                            <p>Dave (03:48.822)
                            But having said that, this is way better than it has any right to be.</p>
                            <p>Bryan White (03:52.602)
                            You know, here&#39;s the thing is I for whatever reason there&#39;s like there&#39;s a whole bunch of like blind spots in the slasher movie Canon for me just and it&#39;s mostly owing to the fact that like I&#39;m just really fucking lazy and You know like why take a chance on something new when I can watch Friday the 13th part two again you know, and so this is one of those movies that just kind of like Lived in the blinds like just like lived in the shadow for me I really sort of like I passed it by about a thousand times on shutter and I had</p>
                            <p>Bryan White (04:21.474)
                            no intention of ever really picking it up. But for this episode, I watched it twice in relatively quick succession, and I enjoyed it both times. I think the second time, since I was really paying attention to it and taking notes, like I really, really liked it. So, you know, yeah.</p>
                            <p>Dave (04:37.802)
                            Yeah, it sneaks up on you. There&#39;s a lot to it. And I&#39;ll tell you, because you say they&#39;re trying to do a lot with the sort of slasher tropes, and they are, like there&#39;s a lot of shit in this that&#39;s like very familiar. But so it falls sort of squarely in my favorite era, which is like 75 to 85. And it&#39;s that.</p>
                            <p>Bryan White (04:55.954)
                            Yeah, you&#39;ve picked most of these movies that we&#39;ve done so far and needless to say, we&#39;ve been addressing a very narrow band of horror history between 80 and 82. So, it kind of, I eventually kind of came to realize, oh, this is just his favorite.</p>
                            <p>Dave (05:13.458)
                            It is. And it&#39;s I think it&#39;s because it&#39;s when it&#39;s like they hadn&#39;t yet figured out what to do with the genre. Like it was clear that it was it was different now. Like it had been remade by Halloween and by Black Christmas and all that. You know, the stuff in the late 70s, it had been kind of reborn as something, but no one knew what that something was yet. And so it&#39;s these kind of weird movies that sort of fumble around and they&#39;re kind of horror movies, but they&#39;re horror movies made by people who are influenced by things from a</p>
                            <p>Dave (05:43.246)
                            previous era. So they&#39;re kind of weirdly tame, but also not tame, like they&#39;re exploitive in a weird way, but not in their plot.</p>
                            <p>Bryan White (05:51.682)
                            Yeah, so I&#39;ve got some I&#39;ve got some notes about that and and I suppose we&#39;ll we&#39;ll get it This is the part where we do the we do the facts about so All right</p>
                            <p>Dave (05:58.006)
                            Well, actually, I&#39;ll tell you one more thing about this, because this is a thought I had while I was watching it, is if you set this against a movie that is from the same year that is relatively similar, Slumber Party Massacre. Now, Slumber Party Massacre written by a noted feminist lesbian, Rita Mae Brown, it is supposed to be a satire. It is a critique of horror movie tropes. It doesn&#39;t really end up that way because it kind of got butchered in between the script and the movie. Yeah.</p>
                            <p>Bryan White (06:24.23)
                            Sure, like the distributor had a different vision, yeah.</p>
                            <p>Dave (06:28.406)
                            But I would argue that this movie is maybe more unintentionally feminist than that is, because this movie passes the Bechtel test time and time again. There are barely any men in this movie. And the ones that are in it are inconsequential and one of them&#39;s the killer.</p>
                            <p>Bryan White (06:44.841)
                            Yeah, this is this is true. Yeah. So this movie was made. This movie was made in 1981, but it was released late in 1982. So other movies released that year, Amityville to the possession. See our episode on Amityville to pieces. Also see our episode on pieces. Really see our episode on pieces. Nobody&#39;s listening to that one for some reason. Also, Jaws of Satan.</p>
                            <p>Bryan White (07:09.826)
                            which is a movie I had never heard of until I was just looking up other horror movies released and that It does not sound like a very good movie. It&#39;s like satan possesses a giant cobra</p>
                            <p>Dave (07:13.514)
                            It is not good. It is, well, it is hilarious. It is hilarious and fun, but God, it is bad.</p>
                            <p>Bryan White (07:22.402)
                            Yeah, also, Jean Rolland&#39;s Living Dead Girl, Extro, and of course, Poltergeist, which is like one of my favorite movies of all time. Yep, little picture. So this was directed by a guy named Mark Roseman. And this is his first directorial feature. He had done, I looked this up, he was straight out of film school, and he worked on, he moved on a,</p>
                            <p>Dave (07:32.372)
                            of it.</p>
                            <p>Bryan White (07:52.098)
                            He was first AD for like a Brian De Palma picture right before this, which is quite a leap because usually. Oh, God, now I wish I had taken it down to camera home movies, maybe. But yeah, yeah, he&#39;s a hilarious guy, that Brian De Palma. Actually, you know, I&#39;ll give him this. Phantom of the Paradise is hilarious. I love that goddamn movie. But yeah, this is his first is his first feature.</p>
                            <p>Dave (07:57.909)
                            What&#39;s the movie, Sisters?</p>
                            <p>Dave (08:03.223)
                            Ah, his comedy.</p>
                            <p>Dave (08:09.867)
                            Especially when it comes to women.</p>
                            <p>Dave (08:14.954)
                            I fucking love that movie.</p>
                            <p>Bryan White (08:20.966)
                            And following this movie, he&#39;d fall in with the Disney Channel in the 80s, and he worked fairly steadily for them, as well as some TV and direct to video just forgettables. But he found his stride working on basically everything with the Disney Channel ever did with Hillary Duff, like, I don&#39;t know, 1520 years ago by this point. One of the blonde Disney Channel girls.</p>
                            <p>Dave (08:38.826)
                            Who is Hilary Duff?</p>
                            <p>Bryan White (08:45.378)
                            What the fuck was her show? Lizzie McGuire? No, it doesn&#39;t matter, but that was he eventually kind of found his niche working with that. The reason and the entire reason that he made this movie was because he figured correctly that his like the fast track to becoming like a director professionally was to direct your own movie.</p>
                            <p>Dave (08:47.751)
                            It probably won&#39;t matter.</p>
                            <p>Dave (09:10.219)
                            Yeah, that&#39;s horror. That is why horror movies exist, because they&#39;re easy and cheap. And that&#39;s everyone&#39;s first movie.</p>
                            <p>Bryan White (09:12.878)
                            Basically, that&#39;s the thing. And part of me always bristles at that because like that&#39;s, I love these movies, like genuinely like in my heart, these like hold a very special place for me. But for the people who made them, it was a really just kind of like a, yeah, I&#39;m just trying to get a job in the industry. And this is like, it costs nothing to make these movies. And you know.</p>
                            <p>Bryan White (09:38.742)
                            especially if they turn a profit, which they all did. Like even Final Exam turned a pretty healthy profit. So like if you made a horror movie, George Romero did it, you know, Night of the Living Dead was supposed to be a fast track to, really what he intended to make was movies like There&#39;s Always a Vanilla, which nobody&#39;s fucking seen that one and everybody&#39;s seen Night of the Living Dead. Sam Raimi, the whole thing with...</p>
                            <p>Bryan White (10:04.618)
                            Evil Dad was, he was like, the movies that I think that he always intended to make was like For the Love of the Game, which is the one that nobody fucking likes. But.</p>
                            <p>Dave (10:12.502)
                            Same with John Carpenter. John Carpenter didn&#39;t intend to be a horror filmmaker.</p>
                            <p>Bryan White (10:16.154)
                            Yeah, yeah, like that&#39;s it was like all of them just kind of leaping in like so I think that I always just kind of like I have much more respect for guys like Joe Dante and like Dario Argento who just wanted to make fucking horror movies which is awesome to me, but you know these other guys who kind of like kind of tripped into the genre by accident, like, they, you know they they&#39;re all legends for a reason and they continue to make great.</p>
                            <p>Dave (10:39.076)
                            There is something a little dismissive about it. It kind of undercuts everything else.</p>
                            <p>Bryan White (10:43.01)
                            It bums me out and there&#39;s certain directors like John Carpenter, I love him. He&#39;s one of, he&#39;s probably my favorite horror movie director but there is, whenever he talks, there&#39;s a weird sort of disappointment that comes through that like his, yeah, he doesn&#39;t, his movies are fucking great. Like, you know, I get it that like the thing was supposed to be the big one and it bombed really hard and people were like super grossed out and</p>
                            <p>Dave (10:56.566)
                            He&#39;s a very bitter man. And I don&#39;t know why.</p>
                            <p>Bryan White (11:11.486)
                            I think that time has been very kind to it. And like nowadays there are legions of fucking fans. There are people, my favorite movie of all time is Escape from New York. I fucking love that movie. But like to hear him talk about it, even today, he&#39;s still kind of bummed about the fact that it just underperformed at the box office.</p>
                            <p>Dave (11:30.562)
                            There&#39;s that Guillermo del Toro story where Guillermo del Toro tells him something like, and this is like, I know, 2017, he tells him something like, you know, that movie was so influential for me and it was really one of my favorite movies. It really blew me away. And he was like, yeah, well, it didn&#39;t do any good at the box office in 1982. It&#39;s like, dude, that was 40 fucking years ago. You&#39;re a goddamn icon. What more do you want?</p>
                            <p>Bryan White (11:49.442)
                            I know, get over it. You could just have, he could have coasted on Halloween. God.</p>
                            <p>Dave (11:57.61)
                            It would be like if Beyonce was just like, well, nobody liked my first record.</p>
                            <p>Bryan White (12:04.095)
                            Yeah, I don&#39;t I don&#39;t know I don&#39;t get it but like the people who like this guy Roastman he got out of it and he kind of found his way but like the other these people who you know who kind of like just rainy and carpenter and all the people that we just mentioned like they kept doing it because they were good at it so you know I don&#39;t know they can hate it all they like I love this shit</p>
                            <p>Dave (12:25.602)
                            See, that&#39;s why I am a huge fan of Vincent Price because Vincent Price, he was not a horror actor. He fell into it sort of and got pigeonholed and he was still like, you know what? It&#39;s fucking fun and I love it. So I&#39;m gonna keep making horror movies now because I have tons of fun and people seem to really like them. Everybody&#39;s having a good time.</p>
                            <p>Bryan White (12:36.139)
                            Yeah. Yep.</p>
                            <p>Bryan White (12:40.79)
                            Yeah. That&#39;s why Vincent Price is the fucking bomb. Yeah. All right, so cast. This has Kate McNeil plays her final girl, Katie or Catherine, they kind of waffle on the name. She was a recurring villain on the soap as the world turns for a few years. And that was even by the time she picked up this role, like she was kind of a known quantity. And then going forward from there, television was her primary scene. She&#39;s still quite active today.</p>
                            <p>Dave (12:44.892)
                            he is.</p>
                            <p>Bryan White (13:10.982)
                            kind of picking up like, you know, guest spots and shit like CSI and stuff like that.</p>
                            <p>Dave (13:14.399)
                            I tell you what, if you are making a movie and you want something done right and fast, get a goddamn soap opera star.</p>
                            <p>Bryan White (13:20.142)
                            Right it kind of worked on here because she&#39;s not the only soap Yeah, yeah, so if you&#39;re a horror movie fan really Because I only know about like the woman who plays Vicky Eileen Davidson she actually Denise walked by while I was while I was watching this and she was like oh she was on days of our life</p>
                            <p>Dave (13:21.922)
                            Because you get two of them in this one.</p>
                            <p>Dave (13:26.386)
                            Actually, you get three of them. Yep.</p>
                            <p>Dave (13:32.546)
                            Eileen Davidson.</p>
                            <p>Dave (13:39.254)
                            A similar thing happened in my house. She was also on the Young and the Restless and Real Housewives of Beverly Hills, I believe.</p>
                            <p>Bryan White (13:43.162)
                            But yeah.</p>
                            <p>Bryan White (13:48.17)
                            Oh, no shit, no shit. Yeah, if you&#39;re a horror fan, then you know Kate McNeil or you probably know her as the love interest in George Romero&#39;s Monkey Shines. Which, have you ever seen that one?</p>
                            <p>Dave (14:01.255)
                            Uh, we went to see the theater.</p>
                            <p>Bryan White (14:05.166)
                            you did, because mom took me, mom took me to see that movie.</p>
                            <p>Dave (14:08.057)
                            No, she took us to the movies, you took us to see that movie.</p>
                            <p>Bryan White (14:12.738)
                            No, no, no, because I&#39;ll tell you how this is this is a very fucked up situation because She got the book at some used book place and loved it and obviously. Yeah. Yeah was adapted from a novel and obviously The book was not as violent as the movie is and it probably doesn&#39;t include the sex scene And so i&#39;m sitting there next to mom into this movie theater As that scene came up and she actually reached over and put her hand over my eyes</p>
                            <p>Dave (14:20.354)
                            That&#39;s a book.</p>
                            <p>Dave (14:43.574)
                            You&#39;d think that the key art for the film with a monkey holding a bloody razor blade would have been a clue that this is not the right film.</p>
                            <p>Bryan White (14:53.539)
                            I know. I mean, I mean, it&#39;s this is what happens when yeah, this is what happens when George Romero like gets his hands on the material. It&#39;s not as explicit as like Day of the Dead, which I think is the movie that he made before that one. So he must have got the gore out of his system with that one. But yeah, Monkey Shines is not a terribly gory movie, but the sex scene is extremely explicit. And yeah, it&#39;s a really fucked up thing to watch while you&#39;re sitting next to your mom.</p>
                            <p>Bryan White (15:20.578)
                            be there.</p>
                            <p>Dave (15:22.798)
                            The third soap star is Harley Jane Kozak, who plays, I think she plays Diane is the name of the character.</p>
                            <p>Bryan White (15:26.931)
                            Oh, okay. She&#39;s the one, I think she&#39;s the one who gets killed first.</p>
                            <p>Dave (15:31.882)
                            Yeah, she&#39;s like the sardonic. She&#39;s the the barb of the.</p>
                            <p>Bryan White (15:36.35)
                            Yeah. So the let&#39;s see the villainous house mother Mrs. Slater is played by a woman named Lois Kelso who wasn&#39;t terribly active on screen. She&#39;s only done this picture. She did a TV spot just small part in a Chris Rock movie. And her but her, her. She was like, whenever we do these movies that were like definitely not shot in California. She&#39;s what we what we colloquially call local talent. She was a stage actress.</p>
                            <p>Bryan White (16:03.598)
                            from the DC area. This movie was made between Virginia and Maryland. So yeah, yeah, so her voice was apparently dubbed for this post, this part in post because it wasn&#39;t nasty enough. Oh, yeah, well, at least that one. I mean, if it is, it&#39;s that they did a hell of a job because I didn&#39;t even realize that that was the thing. I just sort of assumed that that was her voice because it, it</p>
                            <p>Dave (16:10.596)
                            And boy does it show.</p>
                            <p>Dave (16:20.342)
                            This whole, the whole movie I think is dubbed.</p>
                            <p>Dave (16:31.762)
                            I watched it with the mono audio track and it&#39;s a little bit off.</p>
                            <p>Bryan White (16:35.462)
                            Oh, gotcha. It&#39;s, yeah, I mean, it&#39;s possible that they just looped it all. Like they probably had like shit location sound or something. Let&#39;s see. So Eileen, we mentioned earlier, Eileen Davidson plays the awful killer Vicky. She was also a soap actress, as we said, days of her lives, young and the restless. And she&#39;s actually won several daytime Emmys for her work. So good for her.</p>
                            <p>Dave (16:57.409)
                            And she is on two soap operas at the same time. She&#39;s still on them.</p>
                            <p>Bryan White (16:59.854)
                            Damn, she&#39;s fucking a busy woman. So regarding the girls in the sorority, this was the first and last picture for about half of them. And Robin Malloy, who plays my favorite of the bunch, Jeannie is actually a professional musician of note today. Jeannie is my favorite. Yeah, like, so this movie, like, have you ever seen Housu, the Japanese movie? So everybody who watches that movie has got like a favorite house girl.</p>
                            <p>Dave (17:15.474)
                            Genie. Genie is your favorite, huh?</p>
                            <p>Dave (17:22.385)
                            Yes, yes.</p>
                            <p>Bryan White (17:28.278)
                            And I when I was watching this, I was like, oh, okay. So Jeannie&#39;s my kung fu. Cause that was, she was my. No, I can&#39;t remember what her name is. Kung fu is the one who gets, who spends half the movie in her underwear and she kicks the cat picture, but just her lower body does. And yeah, which I&#39;d say we do house who at some point, but, but I don&#39;t know if we could ever actually cover that in a, in a sort of meaningful way.</p>
                            <p>Dave (17:33.079)
                            Is Kung Fu the one who&#39;s eaten by the piano?</p>
                            <p>Dave (17:46.242)
                            Japan</p>
                            <p>Dave (17:51.522)
                            Toho made four horror movies and then after that, they were like, you know what, no more, no more of this. We&#39;re not, we&#39;re not good at this. This is not our thing.</p>
                            <p>Bryan White (17:54.946)
                            Yeah. So, lastly, and I only mentioned this just because his role in the movie is meaningless. Michael Sergio, he plays Rick. He&#39;s Vicki&#39;s boyfriend who gives her the gun. And I only mention him because a few years after this, he&#39;s the guy who parachutes into Shea Stadium during game six of the World Series in 1986 when the Mets played the Red Sox. That was the high point of it. That was basically the high point of his.</p>
                            <p>Bryan White (18:24.898)
                            It was probably a publicity stunt that just never panned out because he went to jail for it.</p>
                            <p>Dave (18:29.738)
                            I was gonna say cocaine is a hell of a drug.</p>
                            <p>Bryan White (18:32.826)
                            That is true. Boy, yeah. So a few notes. The body count in the movie totals nine, which is a respectable number. No, I have not. It&#39;s coming. We&#39;re talking about that fucking soundtrack. Yep. So a respectable number. But the kills in this movie are seriously wimpy. The film&#39;s distributor asked for reshoots and additional gore, which is still very tame.</p>
                            <p>Dave (18:39.83)
                            You still you still skipped the music. You skipped the music every time. All right.</p>
                            <p>Dave (18:57.902)
                            I think they didn&#39;t have any money.</p>
                            <p>Bryan White (18:59.494)
                            It&#39;s probably it. So the movie cost about a half a million dollars. It grossed about 10 million in the box office alone.</p>
                            <p>Dave (19:04.746)
                            Because if anything hurts this movie, it&#39;s not the lack of gore per se, it&#39;s more the lack of action.</p>
                            <p>Bryan White (19:13.179)
                            Yeah. Yeah.</p>
                            <p>Dave (19:14.158)
                            because they obviously didn&#39;t have a lot of money for special effects because the special effects are fucking terrible in this movie. But.</p>
                            <p>Bryan White (19:19.098)
                            They&#39;re pretty they&#39;re pretty bad. That&#39;s probably why they cut away from them so brutally, which we&#39;ll mention just because there&#39;s there&#39;s that&#39;s the one that&#39;s the one sort of tripping point that I get with this whole thing. IMDb trivia notes that the movie got nearly got an X rating, which I have a hard time believing. I think they all say that when it comes back to like DVD commentaries and stuff. Cause if Maniac, I know if Maniac and the Prowler skated by with an R rating, this movie could have gotten a fucking PG were it not for the nudity.</p>
                            <p>Dave (19:40.153)
                            Goddamn waterbed.</p>
                            <p>Bryan White (19:49.878)
                            The production designer on the picture is a guy named Vincent Peraneo, who worked with John Waters as far back as Pink Flamingos. And most of their work together lands in that Golden Waters era of the late 80s to the mid 90s and like crybaby hairspray. Yes, he did. And again, he&#39;s another guy who&#39;s still working. He also worked on Serial Mom, which is a movie that we ought to get to at some point. Pussy Willows Dottie.</p>
                            <p>Dave (20:03.968)
                            I believe he also did a lot of TV.</p>
                            <p>Bryan White (20:18.086)
                            The script supervisor in the movie is a woman named Rachel Talalay, who would go on to do a shit ton of TV here in the UK. Like she&#39;s also still quite active. And like, if you&#39;re listening to this, she&#39;s probably directed something that you&#39;ve seen. She&#39;s done like Doctor Who and The Flash and some of the Disney Marvel stuff that they do for their TVs. She also directed Freddie, she directed Freddie&#39;s Dead, The Final Nightmare. Yep.</p>
                            <p>Dave (20:37.924)
                            I have not I&#39;ve not seen any of them.</p>
                            <p>Dave (20:42.844)
                            I have seen that.</p>
                            <p>Bryan White (20:44.578)
                            So Rosman said that he was inspired by Clues-O, which is some fucking bullshit. I know, just shut the fuck up. What is this shit? The central conspiracy of the prank gone wrong and a hidden witness is shamelessly stolen from Prom Night. It is almost identical to the setup for Prom Night.</p>
                            <p>Dave (20:48.778)
                            Man, shut up. Get the fuck out.</p>
                            <p>Dave (21:01.688)
                            or every single slasher that is based on and then there were none.</p>
                            <p>Bryan White (21:05.827)
                            Yeah, yeah that but also he&#39;d clearly clearly seen Black Christmas right up to right up to the end Yep</p>
                            <p>Dave (21:10.878)
                            Yes, there is heavy, heavy shades of Black Christmas in a way that is not bad, because I will say in an era, I mean, this is pretty much true all horror movies, but horror is sort of notoriously misogynistic and creates kind of one dimensional female characters. These characters are pretty well developed as individual people.</p>
                            <p>Bryan White (21:30.702)
                            That&#39;s the thing, like, each one of them&#39;s got a little bit of a personality, like, in the same way that the Haosu girls do.</p>
                            <p>Dave (21:34.366)
                            And you can tell when he&#39;s pulling that, obviously, from Black Christmas, where they all are distinct characters or Halloween, where they&#39;re all distinct characters.</p>
                            <p>Bryan White (21:42.646)
                            Yeah, yeah. And there&#39;s even yeah, yeah. So he does cite Bava as an influence, which I can definitely see for certain. Like he&#39;s, yeah, like, well, no, but like he, you know, particularly towards the end, it&#39;s that very like the technicolor era of Bava. So like, yeah, get the fuck out of here with that Clueso shit. The whole, the thing is, is that this movie, even though it&#39;s made in American,</p>
                            <p>Dave (21:52.306)
                            I can see a certain era of Bava not across the board.</p>
                            <p>Dave (22:06.77)
                            Yeah.</p>
                            <p>Bryan White (22:11.458)
                            and made it in America by Americans. This movie felt more like a Jallow to me, which is probably the, and then there were none quality to it. The only thing really, yeah, yeah. Like the only thing really missing from it is there&#39;s no fashion shoot going on, you know? So now, the score is composed by Grimbrow&#39;s favorite Richard Band, who is gleefully stealing from Manfred 80s, Friday the 13th themes.</p>
                            <p>Dave (22:16.907)
                            Yes. Yep. Yeah, there&#39;s a there&#39;s an Italian influence on this big...</p>
                            <p>Dave (22:38.69)
                            I was going to say doing his best, his best Jared Goldsmith impression.</p>
                            <p>Bryan White (22:44.295)
                            Yeah, this is actually a bit of like a pastiche. Like there&#39;s definitely some Jerry Goldsmith parts. There&#39;s some like spooky, la la la stuff. And there&#39;s a lot of that stabby, like dun dun dun dun dun dun dun dun, kind of stuff that Manfredini does in Friday the 13th.</p>
                            <p>Dave (22:53.886)
                            Yeah, there&#39;s a lot of that.</p>
                            <p>Dave (23:02.262)
                            I love the fact that Richard Band shamelessly rips shit off all the time and he doesn&#39;t care. Like all of reanimator reanimator is basically just psycho. Yeah.</p>
                            <p>Bryan White (23:05.434)
                            He just steals. It doesn&#39;t.</p>
                            <p>Bryan White (23:10.318)
                            Bernard Herman, it&#39;s psycho, that&#39;s all it is. It&#39;s so great, yeah, I know. The thing is, I don&#39;t give a fuck, man. It&#39;s, he&#39;s so good, he&#39;s so good. And the music, the music was actually like, so he composed it and it was performed and recorded by the London Philharmonic. So this is not like a nickel, this is not, yeah, this is not your, this is not a nickel and dime final exam. It&#39;s.</p>
                            <p>Dave (23:16.958)
                            No, he doesn&#39;t either. He&#39;s like, yeah, that&#39;s exactly what it is. I thought it&#39;d be funny.</p>
                            <p>Dave (23:30.018)
                            That&#39;s where all your fucking money went.</p>
                            <p>Bryan White (23:37.722)
                            Definitely on par with something that was acting with a little bit more money</p>
                            <p>Dave (23:41.75)
                            Yeah, this is like lower to mid tier. This isn&#39;t like Junko in the Woods alone. Like this is a little bit elevated, but not quite too, you know.</p>
                            <p>Bryan White (23:49.078)
                            Yeah. Yeah, like it&#39;s definitely, it&#39;s definitely a director&#39;s first picture, but it doesn&#39;t, it definitely feels like something that came from somebody who was like way more accomplished.</p>
                            <p>Dave (24:01.774)
                            I will also tell you, you said that Prom Night is an influence on this. Prom Night, this beat out Prom Night for this particular pick for this week. It was going to be Prom Night because I love Prom Night. Because I was trying to find a way to work it in there. And I still will because it is a Canadian film and I love Canadians.</p>
                            <p>Bryan White (24:03.363)
                            Oh</p>
                            <p>Bryan White (24:10.118)
                            Oh, no kidding. You said that, no shit, cause you said that last time also.</p>
                            <p>Bryan White (24:16.442)
                            Gotcha. All right.</p>
                            <p>Bryan White (24:21.05)
                            prom night is? No shit, I had no idea. Alright, so it is 1961 and it was a dark and stormy night because of course it was. We opened it up on some sort of a like a hospital where a woman is having difficulties with labor and the the doctor has to cut her open.</p>
                            <p>Dave (24:22.154)
                            Yeah, same guy who made curtains.</p>
                            <p>Dave (24:44.523)
                            It&#39;s a dark and stormy black and white night.</p>
                            <p>Bryan White (24:47.266)
                            My version is tinted blue. I think that like</p>
                            <p>Dave (24:49.614)
                            Ah, so my theory is that because they couldn&#39;t afford much by way of special effects, they were like, okay, how do we make her look a little bit younger? Because she looks exactly the fucking same as she does later.</p>
                            <p>Bryan White (25:02.47)
                            I know what the thing is, is like going into this, I had really no idea what was going on. And so when they show the woman in labor, I&#39;m like, that lady looks a little old to be having a baby. And then it&#39;s... Yeah, like, I mean, they do their best, but like when she shows up in the subsequent scenes, I&#39;m like, oh, oh my God, they just like, like put like a cowl over her head, this sort of like, you know.</p>
                            <p>Dave (25:12.31)
                            Yeah, I think I know why this pregnancy is risky. I mean.</p>
                            <p>Dave (25:26.411)
                            Yeah.</p>
                            <p>Bryan White (25:28.346)
                            But yeah, so she is, she&#39;s struggling and there&#39;s a little, there&#39;s like a lot of chatter between like the doctor and the nurse and there&#39;s a lot of closeups on like a pregnant woman&#39;s stomach and then this woman like having a hard time and the dialogue is super misleading. What it sounds like is.</p>
                            <p>Bryan White (25:47.71)
                            she asks like where&#39;s the baby after it&#39;s all done and everything&#39;s kind of kind of quieted out she asks where the baby is the doctor&#39;s evasive and then she screams so it sounds like the baby is lost that&#39;s a reasonable assumption yes</p>
                            <p>Dave (26:04.073)
                            Accept.</p>
                            <p>Bryan White (26:04.382)
                            So yeah, except we then cut to the present as titles over a montage set up to set up our victim pool. And there&#39;s a lot of close ups montage of zany girl stuff happening over.</p>
                            <p>Dave (26:18.028)
                            Yeah, this whole thing has like a vibe of everyone&#39;s getting ready to go on vacation.</p>
                            <p>Bryan White (26:22.142)
                            It really, it&#39;s almost like that, that, it really needed a song like in the mutilator, that goofy like Billy Joel song, or it needed some kind of new wave, like eighties kind of thing going on instead of the, you know, the kind of strings that we get, the Jerry Goldsmith stuff.</p>
                            <p>Dave (26:39.35)
                            Yeah, my note just says, this is ladies doing lady stuff.</p>
                            <p>Bryan White (26:43.086)
                            That&#39;s really what it is. They&#39;re like, they&#39;re like, they, they open up closets and like boxes of shoes fall out. Like it&#39;s a, you know, it&#39;s, it&#39;s overstuffed. They&#39;re like shaving their legs and shit. Yep.</p>
                            <p>Dave (26:50.958)
                            like laughing and shaving their legs. Like it&#39;s it&#39;s just lady stuff.</p>
                            <p>Bryan White (26:55.266)
                            Yep. And Catherine is is we then finally that we come to the thing we come up that we meet our final girl Catherine she&#39;s in her room she&#39;s packing up all of these girls have just graduated which is also part of the sort of establishing shots and they in the montages they&#39;re all in their car to caps and guns so that this is the last couple of days that they&#39;ll be in the sorority house. Catherine&#39;s getting ready to move out. And her mother looks out the window and we see check of swimming pool.</p>
                            <p>Dave (27:25.698)
                            That&#39;s what my note says too.</p>
                            <p>Bryan White (27:27.466)
                            I know, I knew it, I knew it. I knew we were gonna get one of those. But yeah, I mean, also that&#39;s kind of, that&#39;s sort of becoming an us thing. Along comes Vicky, who sucks.</p>
                            <p>Dave (27:41.538)
                            Yeah, they&#39;re about to smack you in the face with the Madonna whore dichotomy. Because, I mean, Catherine could not look any more pure unless they put her in like a fucking holly hobby dress. She is dressed like little house on the prairie for half of this movie. Meanwhile, Vicky is a sultry bitch the whole time.</p>
                            <p>Bryan White (27:46.302)
                            Oh God, yeah, yeah, and it really is.</p>
                            <p>Bryan White (27:57.61)
                            It is so f-</p>
                            <p>Bryan White (28:01.502)
                            Oh, I know, I know. And this is the thing, like, it really does kind of say something about the way that society received Halloween in the moment, because like we talked about this last time, and we&#39;re probably gonna mention it every time we do a slasher movie, because it was so fucking ubiquitous in these movies, was the final girl is always the chased good girl, and she is just surrounded by whores. And it&#39;s just, it&#39;s such a weird way to look at it, because like, I&#39;d never seen that until like,</p>
                            <p>Bryan White (28:31.766)
                            Around the time that that book, was it Men, Women and Chainsaws came out? Like that was the first time that I think anybody had really ever used that word as final girl. And we got that sort of assessment of like, this is how people saw it. I was like, God damn, really? And then I sort of went back and saw these movies and I was like, God damn, they really did sort of like, think that like all of Laurie Strode&#39;s friends were just itching to get killed for their transgressions.</p>
                            <p>Dave (28:36.114)
                            Oh, men, women, chainsaws, yeah.</p>
                            <p>Dave (28:56.843)
                            And to them I say, you can all fuck off because every single one of them is a goddamn queen and they&#39;re amazing.</p>
                            <p>Bryan White (29:03.846)
                            Yep, yep. Except for Vicky, fuck her.</p>
                            <p>Dave (29:07.018)
                            I kinda love Vicky in this because she is such a weird bitch.</p>
                            <p>Bryan White (29:12.665)
                            She&#39;s wise beyond her years. She...</p>
                            <p>Dave (29:14.242)
                            She has an intensity to her. Like, she, you never, she&#39;s either gonna have sex with you, she might stab you, she might just rob you, you never know. She is coming at you with both barrels and I.</p>
                            <p>Bryan White (29:21.956)
                            Yeah.</p>
                            <p>Bryan White (29:25.11)
                            Yep, so we then also get Vicky has set up Catherine with a blind date for like this big party like big going away party. And so now we cut away back to the hotel, the hotel, the hospital and enter Mrs Slater. She&#39;s the she&#39;s the sorority house mother. And I looked this up like I had no idea what a house mother did. I was sort of vaguely aware of house mothers as a thing.</p>
                            <p>Dave (29:52.558)
                            Are you not familiar with Black Christmas? Because that has the house mother to end all house moth-</p>
                            <p>Bryan White (29:55.77)
                            Yeah, but-</p>
                            <p>Bryan White (29:59.962)
                            Yeah, but like the concept of a house mother I was like what the fuck even is a house mother cuz like you you only yearly hear about it in the context of sororities</p>
                            <p>Dave (30:07.47)
                            Do they still exist? Is that a store thing?</p>
                            <p>Bryan White (30:10.306)
                            I looked I actually looked at this up because I was like what the fuck is a house mother and it turns out that like Not every sorority house has one. It really depends on the sort of the The laws that the school puts out for their whole like Greek system so some schools require you to have like every every frat house or sorority house or in some cases just the sorority houses What they need is a resident advisor and house mother is really kind of the colloquial name</p>
                            <p>Dave (30:39.7)
                            So they don&#39;t have to be a 70 year old lady.</p>
                            <p>Bryan White (30:43.082)
                            No, I read a story on one of them about a guy who like the the the ra in their frat house was the guy they bought all their weed off of so like This is a stereotype essentially that we&#39;re that we&#39;re getting surprised it&#39;s yeah Yeah, so she goes to the hospital that presumably we were at in the beginning It&#39;s it&#39;s it&#39;s it really hits you over the head with</p>
                            <p>Dave (30:56.398)
                            And a great one, a great one.</p>
                            <p>Dave (31:02.422)
                            Yeah, this whole scene has a real top men kind of quality to it.</p>
                            <p>Dave (31:09.194)
                            Everything is spoken in hushed tones.</p>
                            <p>Bryan White (31:13.29)
                            she stops to watch a little boy play with a sort of look of longing on her face. She goes in and she has a very short, very curt conversation with the doctor from the previous childbirth scene. That&#39;s done in a very, again, the same ambiguous language as the opening establishing scene where the doctor is like, the condition is worsening.</p>
                            <p>Bryan White (31:39.962)
                            You know, this can&#39;t keep up for as long. Like something terrible is gonna happen. And she&#39;s like, fuck you. I&#39;m going ahead with it. I&#39;m closing the house on the 19th. It&#39;s gonna be fine. Don&#39;t worry about it. So what we learned is that while the girls are planning on having this big party, the house is supposed to be closed. And my question is,</p>
                            <p>Bryan White (32:05.27)
                            If this all has to do with like behavior or some other sort of life threatening illness, why is she talking to a gynecologist about this? So.</p>
                            <p>Dave (32:14.67)
                            Yeah, I got a lot of questions about this doctor. I kind of feel like this is an unfinished plot.</p>
                            <p>Bryan White (32:20.85)
                            Yeah, well, that&#39;s the thing when we get to it toward the third act like when he becomes more prominent character I feel like they slept on a lot of Opportunity there to sort of make this a little bit more than it was But I also get the feeling that they were running out of time and they were running out of money So we kind of we get what we get So we cut again to Vicki and her boyfriend Rick who were climbing up into some kind of tree house, maybe</p>
                            <p>Dave (32:48.081)
                            I think it&#39;s an old barn.</p>
                            <p>Bryan White (32:50.026)
                            It&#39;s when I looked at it, I was like, this is clearly a house that was built in tree. Like, this is like Swiss Family Robinson shit. But it looks like like the backstage area for maybe a theater that was built in a treehouse. I don&#39;t know. But he he gives her Chekhov&#39;s actual gun.</p>
                            <p>Dave (33:10.874)
                            That everything about this is a bad idea. I don&#39;t know anything about guns. I know enough to know this is not how you handle them.</p>
                            <p>Bryan White (33:17.506)
                            Yeah, so I was bristling a little bit because I&#39;ve used a gun of that fashion before, the 1911. It is a really, really powerful gun. It is extremely loud as well. That&#39;s whenever I see people using guns in these, having actually been at a range and used firearms, I get the feeling that without the headgear that dampens the noise, I would be.</p>
                            <p>Bryan White (33:44.774)
                            death after like five rounds and these people these two are like hanging</p>
                            <p>Dave (33:48.644)
                            She&#39;s firing this thing off like fucking dirty Harry, and he is like licking her ear while she&#39;s doing like, this is very dangerous. I don&#39;t think this is a good idea at all.</p>
                            <p>Bryan White (33:52.214)
                            And she&#39;s good. Yeah, like he&#39;s. This is very improper gun safety. But yeah, we&#39;re going to learn. Vicki don&#39;t give a fuck. She don&#39;t give a fuck, though, as we come to find out who doesn&#39;t. Yeah, so again, we cut. So this was, again, another sort of establishing scene. We&#39;re putting the gun in Vicki&#39;s hand.</p>
                            <p>Dave (34:02.626)
                            This is what&#39;s wrong with America, Vicki.</p>
                            <p>Dave (34:08.078)
                            She really does not, and that&#39;s kinda why I like her.</p>
                            <p>Dave (34:17.942)
                            But they do this hard cut and this is, I don&#39;t know if there&#39;s a name for this, it probably is, but it&#39;s when they cut, they fire the gun and it coincides with the popping of a champagne cork. It&#39;s sort of like when someone gets their head cut off or something and then it&#39;s like a splat of ketchup on a plate in a diner somewhere.</p>
                            <p>Bryan White (34:23.271)
                            Oh yeah!</p>
                            <p>Bryan White (34:34.222)
                            Yeah, yeah, I would love to know it. I guarantee that that&#39;s got an industry name I would love to know it is because that&#39;s what it is is Vicky like fires another shot, but we hard cut to the girls in the dorm Minus Vicky and they&#39;re all drunk and in their underwear because that&#39;s you know</p>
                            <p>Dave (34:49.77)
                            And now you&#39;re gonna meet them all. So good.</p>
                            <p>Bryan White (34:52.494)
                            Yep, we go around the table. I, like I said, I&#39;m team Genie all the way. She&#39;s a little frumpy. There&#39;s, let&#39;s see, I gotta try to remember this. There&#39;s...</p>
                            <p>Dave (35:03.918)
                            She does have kind of a Mormon vibe to her.</p>
                            <p>Bryan White (35:05.698)
                            She is, because the others are a little loose in there, the way that they dress, and she&#39;s always, I think she even goes so far to have a cross on at one point. But there&#39;s, let&#39;s see, there&#39;s Liz, there&#39;s Donna?</p>
                            <p>Dave (35:12.494)
                            Yes.</p>
                            <p>Dave (35:19.375)
                            Is it Donna or Diane? That&#39;s that&#39;s Harley Jane Kozak. She&#39;s she&#39;s my favorite. She&#39;s my actually she&#39;s my she&#39;s my second favorite. You know who my first favorite is.</p>
                            <p>Bryan White (35:21.198)
                            Diane!</p>
                            <p>Bryan White (35:24.654)
                            Yeah. Ha ha ha ha ha.</p>
                            <p>Bryan White (35:28.33)
                            Yeah, yeah, so we got we got Katherine also then there&#39;s Morgan who&#39;s the kind of kind of stupid one I think that&#39;s kind of like her gimmick</p>
                            <p>Dave (35:32.11)
                            Whoo!</p>
                            <p>Dave (35:35.822)
                            She watching Morgan, and I&#39;m sure this is how she was directed to act. I am not going to assume this woman is a bad actor, but it is like watching someone have a stroke for 90 minutes.</p>
                            <p>Bryan White (35:48.758)
                            She&#39;s very childish.</p>
                            <p>Dave (35:51.096)
                            Yeah, in the greatest, weirdest way.</p>
                            <p>Bryan White (35:53.922)
                            Yeah, yeah. So let&#39;s see. So Vicki&#39;s out. We got Catherine. We got Liz. We got Diane. We got Jeannie. We got Morgan. And there&#39;s one more. And her name is... I can&#39;t remember. Stevie. Stevie. And each one of them does a little like, like a toast to like, you know, hey, to the future sort of thing, but it&#39;s a little jokey. And I can&#39;t remember which one of them it is.</p>
                            <p>Bryan White (36:20.39)
                            She&#39;s like my dad says that I was born with a silver spoon in my mouth, but it was up my nose So yeah, yeah like this was this was before my This was a before Miami Vice and like blows still have like its celebrity status Like they were still given like they&#39;re still putting it in high times magazine</p>
                            <p>Dave (36:26.758)
                            Yeah, whoa, wow. You&#39;re coming in hot.</p>
                            <p>Dave (36:42.078)
                            And everyone had like a little Coke spoon they kept around their neck.</p>
                            <p>Bryan White (36:44.182)
                            Yep. Yeah, they had a little around the neck. Yep. Yep. So they what what we find out is okay, because because as they&#39;re partying, they&#39;re drunk, they&#39;re all they&#39;re all drinking in walks, Mrs. Slater, who all know that none of these girls are even supposed to be there. But she&#39;s</p>
                            <p>Dave (37:01.878)
                            Yeah, spoiler alert, everyone. Mrs. Slater is a bitch about everything.</p>
                            <p>Bryan White (37:06.018)
                            Yeah, she&#39;s a fucking hard ass. And she&#39;s not mostly angry that they&#39;re still there. She&#39;s angry that they&#39;re drinking. And she basically calls them a bunch of like sluts, like to their face.</p>
                            <p>Dave (37:17.438)
                            Yeah, she does. And this is not the last time she will do that.</p>
                            <p>Bryan White (37:21.562)
                            No, no, no, no. So this.</p>
                            <p>Dave (37:24.75)
                            She is the Roger Ebert of movie character moms. Roger Ebert of House Mothers.</p>
                            <p>Bryan White (37:28.038)
                            Yeah, she, these girls don&#39;t give a fuck. They know they&#39;re having this party whether she wants them to or not. We find out it&#39;s because they have to move it from their original location to the sorority house for lack of funds, probably because they spent all their money on coke. Because that drugs very expensive. We move to Mrs. Slater&#39;s room.</p>
                            <p>Bryan White (37:56.49)
                            in the aftermath of this scene and she&#39;s looking over she&#39;s got like a wall of photos of like sorority house lineups in the past and like in these pictures like she looks like happy you know or at least you know like satisfied maybe she doesn&#39;t like she seems to sort of enjoy her role but then she gets to the one of all of the girls and</p>
                            <p>Bryan White (38:22.886)
                            We are introduced to the deadliest fucking cane I have ever seen in a movie. Like it is topped with a cane topper you could kill a werewolf with.</p>
                            <p>Dave (38:32.469)
                            Yeah, this seems like a bad idea for a cane. Very sharp. Very sharp.</p>
                            <p>Bryan White (38:34.698)
                            If you are wobbly and prone to falling down, of walking a stick that has not just a sharp end to the cane topper, but also the end of the cane is used on numerous occasions to stab these girls.</p>
                            <p>Dave (38:50.122)
                            Yeah, this is it is the primary weapon of murder.</p>
                            <p>Bryan White (38:53.602)
                            Yep, I don&#39;t know if I would want, I just don&#39;t think that that&#39;s a very smart item to have, like to steady myself with. I&#39;m probably gonna stab myself more than I&#39;m gonna like stay upright with it. We cut back to the girl scene where this is Vicki and Rick return to the sorority house and she&#39;s like, give me a second, I need to get ready for it. She goes up to a room and then he eventually comes up.</p>
                            <p>Dave (39:23.432)
                            OK, I got to I got to interject here. See, I don&#39;t know much about women or what makes them alluring. But here&#39;s what I do know. She is on this waterbed trying to look erotic, I&#39;m guessing. She&#39;s just jiggling around on a waterbed and looking at this thing. And I&#39;m like, oh, yeah, this is not this is not doing what you think it&#39;s doing.</p>
                            <p>Bryan White (39:23.602)
                            and</p>
                            <p>Bryan White (39:39.878)
                            I... Yeah.</p>
                            <p>Bryan White (39:46.482)
                            So let me say, as a dude who&#39;s into chicks, I&#39;m into it. The undulating surface of the waterbed undoes the entire thing though. So 82 must have been a peak, must have been peak waterbed season because this is the second time in only eight episodes of this podcast where a waterbed has featured prominently with sexual connotations in a movie. That of course is.</p>
                            <p>Dave (39:57.353)
                            That&#39;s what I&#39;m saying. Like this.</p>
                            <p>Bryan White (40:14.638)
                            The other one we did was pieces where nothing is, was it nothing is more beautiful than smoking pot and fucking on a waterbed?</p>
                            <p>Dave (40:21.474)
                            There&#39;s there&#39;s nothing better. Yeah, the best things in life are fucking out of waterbeds, smoking pot. I know something brilliant like.</p>
                            <p>Bryan White (40:29.026)
                            Yeah, see also pieces basically it&#39;s a good one. But I&#39;m watching this, I&#39;m thinking this month, these things must have been featured in Playboy or something because they are the fucking worst and they seem very, they&#39;re like everywhere in these movies. Also Vicky&#39;s line at the end of that scene is supremely gross. She said, so like what I said was like, I&#39;m into it, I was feeling it. When she said this, I was like, okay, you have a very unpleasant, like,</p>
                            <p>Bryan White (40:59.786)
                            relationship with your father because she says look what daddy bought his little girl Because here&#39;s the thing I went and I looked up the history of waterbeds because I was interested in uh, Like why this was such a thing that we&#39;re encountering and I found a really great article from the atlantic in 2002 about the rise and fall of the waterbed this turns out. Yes, they were in playboy They were invented in 1968 They were advertised in the 70s with lines like two things are better on a waterbed. One of them is sleep</p>
                            <p>Dave (41:27.618)
                            Fucking and smoking pot.</p>
                            <p>Bryan White (41:29.838)
                            Yeah, could have been in pieces. It said that because original designs were filled with liquid cornstarch, which engulfed the people on the bed like the blob. And they were, but the thing is, they were designed originally with therapy in mind. So for comfort and relieving pressure points, because, you know, spring mattresses were the thing at the time, providing better night&#39;s sleep. But because the seventies was gross, everybody&#39;s mind jumped at the thought of fucking on one.</p>
                            <p>Bryan White (41:58.922)
                            and having actually been on one, I&#39;m really not sure how that works.</p>
                            <p>Dave (42:02.71)
                            No, it just seems impractical. Like, where do you find purchase?</p>
                            <p>Bryan White (42:05.774)
                            And also, like maybe everybody was just fucking super horny in the 70s because like how your mind just jumps to that conclusion because it is a wildly undulating surface under even the most optimal of conditions. Like it just</p>
                            <p>Dave (42:19.07)
                            I think this is what happened. Everyone is sexually repressed forever. And then all of a sudden in the late 60s, they don&#39;t have to be, and they&#39;re like, oh my God, everything is sexy. And now we are like 60 years out and we&#39;re like, okay, look, some things are sexy, not everything. Some things are just fucking gross and nauseating. Stop it. Grow up, everybody.</p>
                            <p>Bryan White (42:29.69)
                            That&#39;s gotta be it.</p>
                            <p>Bryan White (42:38.003)
                            Yep, yep, yep. I know. Are we not adults here?</p>
                            <p>Bryan White (42:44.282)
                            So who Vicky Fox on the waterbed and Mrs. Slater demonstrates the deadly nature of the cane on the waterbed and it&#39;s set up like a scene where like the killer is coming together.</p>
                            <p>Dave (42:54.622)
                            Her reaction just seems way outsized here. Like, I mean, sure, Vicky&#39;s been a bitch, but like, she comes at her with that cane and she&#39;s gonna kill her.</p>
                            <p>Bryan White (43:04.934)
                            Super, super oversized reaction. But again, what we just heard in the sort of establishing scenes at the hospital is, you know, psychotic reactions. You know, you&#39;re heading to a break sort of thing. So we&#39;re to assume that Mrs. Slater is like, she has some sort of mental condition that&#39;s just getting worse.</p>
                            <p>Dave (43:20.542)
                            Also, this scene wouldn&#39;t be nearly as dramatic if it was just like a regular mattress. Wouldn&#39;t even happen, probably.</p>
                            <p>Bryan White (43:26.062)
                            Nope, nope. I mean, that thing would have got hooked up on the springs or it would have been like a comedy thing where it was like one of those ones that was just like mythically sealed with like down feathers in it and would just put feathers going everywhere. It just, it wouldn&#39;t have worked the same way but it was just like in pieces where fucking water goes everywhere.</p>
                            <p>Bryan White (43:43.062)
                            Um, so the next day the girls are all out by that nasty-ass poll. The thing that I completely</p>
                            <p>Dave (43:48.722)
                            And what and what are they doing around that nasty ass pool? They&#39;re eating fried chicken out of a bucket. God damn, man, the fucking 80s.</p>
                            <p>Bryan White (43:54.835)
                            Here. Yeah, yeah. I know, I know. God, you know, I tell you what, I do kind of miss the old KFC buckets, but.</p>
                            <p>Dave (44:05.278)
                            You could probably still get one. I don&#39;t recommend it. It&#39;s probably not good for you, but.</p>
                            <p>Bryan White (44:07.425)
                            I would assume.</p>
                            <p>Bryan White (44:10.594)
                            I mean, of course, that&#39;s fast fast food fried chicken But the thing that I completely skated by when I talked about we talked about Chekhov&#39;s swimming pool is this pool It&#39;s not just a swimming pool. It is a fucking swamp. It&#39;s gross It&#39;s green. I know So it&#39;s green. It&#39;s got all kinds of shit floating on the surface of it. It&#39;s really really nasty. Nobody wants to go in it The girls are all pissed off</p>
                            <p>Dave (44:23.126)
                            which raises questions later on.</p>
                            <p>Bryan White (44:37.734)
                            about the way that Mrs. Slater has been acting. And Vicki in particular, like she&#39;s really upset about this waterbed. And a matter of fact, like one of the other girls is like, can&#39;t you fuck somewhere else? And she&#39;s like, she just came in and destroyed my waterbed. Yeah, she came in and-</p>
                            <p>Dave (44:50.094)
                            I love that though. But she&#39;s just like, what? Vicky, fuck like a normal person. See, she&#39;s thinking ahead. She&#39;s future oriented. She&#39;s like, it&#39;s not all sexy all the time. Come on, Vicky.</p>
                            <p>Bryan White (44:56.047)
                            Yeah.</p>
                            <p>Bryan White (45:00.866)
                            Yep. Yep. But they, and so they all, they&#39;re all like, okay, yeah, we&#39;ll just do an old fashion sorority prank on her. And they all start brainstorming the most banal shit. Like, what if we put a bucket of water over her door and it will fall down on her once she walks in the door? Or what if we light a bag of dog shit on fire? And Vicky is like, Vicky, well, the thing is,</p>
                            <p>Dave (45:22.166)
                            Ricky has a different idea. What if I shoot her in the chest?</p>
                            <p>Dave (45:26.858)
                            That hilarious old prank, just like the school shooting prank in Final Exam.</p>
                            <p>Bryan White (45:30.774)
                            I know. Oh my god, the similarities between these two movies are really playing out. But the thing is, in the moment, Vicki doesn&#39;t really even say what her plan is. She goes, I got it. And she looks at the other girls who kind of argue a little bit. And then she&#39;s like, well, are you with me or not? And I&#39;m thinking it&#39;d be helpful if you said what your plan was. But it cuts to the next scene where they&#39;re all sort of</p>
                            <p>Bryan White (45:58.33)
                            handling the gun like Vicky is passing it around and they&#39;re all like Scared of it. Obviously like they&#39;ve never they&#39;ve never handled such a thing and So we still at this moment. We still don&#39;t know what Vicky&#39;s plan is But it it involves a gun apparently so the next morning mrs. Slater wakes up and She turns to her little like cane holder and the cane is missing and so she goes down to where the girls are all down at the pool</p>
                            <p>Bryan White (46:28.066)
                            And Vicky turns into a fucking blonde villain in this scene where she reveals that the cane is floating on like an inner tube in this disgusting pool.</p>
                            <p>Dave (46:44.018)
                            On the fucking grossest old timey inner tube I&#39;ve ever seen.</p>
                            <p>Bryan White (46:47.906)
                            Yeah, yeah, it looked like it was like it was from a truck from the 30s like it&#39;s yeah And she&#39;s like so go get it and mrs Slater&#39;s like fuck you you go get it and this is when vicki pulls the gun on her And everybody kind of like shuts up for a moment And so Mrs. Slater doubts the authenticity of the gun to which vicki turns and fires it at like a lamp and breaks the light You know with the bullet and so</p>
                            <p>Dave (46:51.916)
                            Yeah.</p>
                            <p>Dave (47:17.297)
                            Keep that in mind, I want to come back to that as well.</p>
                            <p>Bryan White (47:19.162)
                            Cause I think you&#39;re thinking what I&#39;m thinking. So she then points it back at Mrs. Slater and what is it? There&#39;s a bit of a struggle. And then she shoots, Vicki shoots one of the girls on the leg who has a wound now or so it seems. And then, so that&#39;s Stevie who gets shot. And then finally.</p>
                            <p>Bryan White (47:48.098)
                            Mrs. Slater is like in the pool. She&#39;s been sort of tricked into this and then again they shoot Stevie who gets hurt and so she comes up out of it and then Vicki turns and shoots Mrs. Slater But nothing happens to her</p>
                            <p>Bryan White (48:03.51)
                            And then there&#39;s a bit of a struggle and a tussle for the gun and the gun goes off and mrs Slater gets shot in the fucking chest and dies from a gunshot wound So let&#39;s go over the sort of contents of this guns magazine because The first shot was a live round apparently The second shot was a blank The third shot was a blank The fourth shot was a live round</p>
                            <p>Dave (48:13.514)
                            Classic prank, classic stuff.</p>
                            <p>Dave (48:33.01)
                            And she says later, I didn&#39;t know there would be another round in the gun. And I thought to myself, why are there just a mix of blanks and live rounds in this gun? This is a very poorly planned prank.</p>
                            <p>Bryan White (48:40.826)
                            This, that&#39;s a very risky proposition.</p>
                            <p>Bryan White (48:46.262)
                            Yeah, but Mrs. Slater is like legit dead dead like she&#39;s just dead There&#39;s no like death scene or anything like that. Like she she falls into the pool and girls go and get her and there</p>
                            <p>Dave (48:57.33)
                            And Katie, Katie who was dressed like fucking Cindy Brady, she is having none of this.</p>
                            <p>Bryan White (49:02.871)
                            Yeah, she&#39;s the one who&#39;s like I&#39;m gonna go and do the right thing I&#39;m gonna go call an ambulance and all of the girls are like wait, we&#39;re gonna get in trouble if you do that</p>
                            <p>Dave (49:10.902)
                            Yeah, one of them was like, what about my job at Pan Am? I was like, Jesus, you got weird priorities.</p>
                            <p>Bryan White (49:13.642)
                            I know. Yep. So they proceed to cover this up hastily. They...</p>
                            <p>Dave (49:23.702)
                            Yeah, she says, one of them says, go and get as many towels as you can. And the girl runs into the house and comes back with two towels. You ran into a house, a sorority house full of people and you found two towels?</p>
                            <p>Bryan White (49:27.939)
                            Yeah.</p>
                            <p>Bryan White (49:33.292)
                            Yeah.</p>
                            <p>Bryan White (49:37.622)
                            Yep. So they wrap her up in the towels, they tie her up and they throw her into the pool and she sinks and then the girls. Yep. Just the band is right.</p>
                            <p>Dave (49:46.208)
                            Just as the band is arriving. And they send Morgan to stall the band, and my note just says, oh no, don&#39;t send Morgan, she&#39;s an idiot.</p>
                            <p>Bryan White (49:58.663)
                            Yep, so they roll her into the swimming pool and she sinks and then they all walk away and then because you know physics being what they are Mrs Slater resurfaces and now it&#39;s party time.</p>
                            <p>Dave (50:12.214)
                            Get our cars rip off band.</p>
                            <p>Bryan White (50:14.511)
                            I actually call them, I call them great value sparks.</p>
                            <p>Dave (50:17.962)
                            Yeah, it reminded me of, hey, who here likes the Doobie Brothers? Because we got one of them.</p>
                            <p>Bryan White (50:25.031)
                            So, but before before like this there&#39;s a whole like establishing shot the party&#39;s going on it&#39;s packed we got this band playing everybody&#39;s dancing, and we go back outside and it&#39;s nighttime now and somebody out by the pool recovers the cane and tosses off the blankets that Mrs Slater was wrapped in which is a cut which causes a continuity error that you probably you&#39;d only notice if you&#39;re me but whatever. But yeah we cut back in. This is a band.</p>
                            <p>Bryan White (50:51.514)
                            called Four Out of Five Doctors, whose Wikipedia page was very clearly written by a member of the band, by the way. They also appear in the movie, The Boogieman. So this isn&#39;t even the first horror movie that they&#39;ve been in.</p>
                            <p>Dave (50:53.518)
                            They&#39;re a real band.</p>
                            <p>Dave (51:04.422)
                            Wait, which one? Not the not the.</p>
                            <p>Bryan White (51:08.026)
                            The Boogeyman is the American Boogeyman, yeah. That&#39;s another one that I haven&#39;t seen. It&#39;s been a weird, yeah. Their music is available on Spotify. It&#39;s not bad. I said, yeah, they&#39;re kind of like the Cars and Big Star at the same time. Like that&#39;s really their thing. So now, you know, this is, things are ramping up. There is a rando party-goer who&#39;s just wandering around through the bushes.</p>
                            <p>Dave (51:09.922)
                            The American one. OK. That movie fucking sucks. It&#39;s real garbage.</p>
                            <p>Dave (51:22.934)
                            Yeah, they&#39;re just a car&#39;s ripoff.</p>
                            <p>Dave (51:37.786)
                            This scene doesn&#39;t make any sense. Like what? Who is he talking to? And he&#39;s talking about like squirrels biting his dick.</p>
                            <p>Bryan White (51:39.49)
                            does not make any sense. Who is he talking to? He&#39;s the only one there. Hey, you gotta, he&#39;s like, he&#39;s like, you gotta look out for squirrels. Like, and then, and then he gets stabbed in the throat, or rather a fairly convincing dummy of him gets stabbed in the throat.</p>
                            <p>Dave (51:55.126)
                            Yeah, what is arguably the most violent scene in the movie?</p>
                            <p>Bryan White (51:58.958)
                            I actually, I think Jeannie&#39;s death takes that, but this is also up there. We cut back to the party and now enter Peter. Peter is like a late motif that nobody asked for. He&#39;s basically, he&#39;s Catherine&#39;s blind date. He&#39;s the guy that Vicky set her up with. And he, for just, for just no real reason,</p>
                            <p>Dave (52:10.946)
                            Fuck it, Peter. Peter sucks.</p>
                            <p>Bryan White (52:27.614)
                            he just sucks. Like there&#39;s nothing really particularly shitty about him. His behavior is appropriate at times and completely like inappropriate at others. He just keeps entering into the scene. His job in the movie is essentially being told to fuck off by Catherine. Like that&#39;s the whole, that&#39;s all he does in this movie.</p>
                            <p>Dave (52:46.846)
                            Peter, nobody wants you here. Read the goddamn room.</p>
                            <p>Bryan White (52:51.19)
                            Yeah, and so he comes up and this scene is about this is a blind date the scene is about as as real-life awkward as an actual blind date. I think he&#39;s supposed to be a MacGuffin I really can&#39;t be sure because otherwise I don&#39;t know what the point of this character is. His role in</p>
                            <p>Dave (53:07.15)
                            Probably because someone was like, hey, you know, you&#39;ve got no dudes in this movie. Now to that I say, thank you. Their response seems to be, okay, here&#39;s Peter.</p>
                            <p>Bryan White (53:10.51)
                            That could... Ha ha ha ha, yep.</p>
                            <p>Bryan White (53:16.93)
                            Yep. So then we cut out to back down towards the pool and crazy party hijinks are ensuing. Two.</p>
                            <p>Dave (53:24.086)
                            So here&#39;s my question, here&#39;s where I&#39;m getting to questions about this one. I don&#39;t understand why these dudes want to get in the pool.</p>
                            <p>Bryan White (53:30.85)
                            No, this is before that. This is when, this is when all, yeah, this is when all the girls run down to the pool and those two guys have Jeannie by the legs and the arms, they&#39;re swinging, you&#39;re like they&#39;re gonna throw her into the pool. Now, I think this scene is supposed to be funny, but to me, it comes off as wicked rapey. Like it&#39;s, yeah, like, and her, her skirt&#39;s like way up around her chest and shit and like she&#39;s not in on the fun.</p>
                            <p>Dave (53:32.391)
                            Oh wait, this is the when they&#39;re gonna throw her in. Okay.</p>
                            <p>Dave (53:40.894)
                            This is basically just assault.</p>
                            <p>Dave (53:45.214)
                            It is not.</p>
                            <p>Dave (53:48.374)
                            They are attacking this woman!</p>
                            <p>Dave (53:57.022)
                            And the only way they get them to put her down is saying, wet t-shirt contest inside. To that I say, kill these guys too.</p>
                            <p>Bryan White (54:06.034)
                            I know if only like if only they had also walked into the bushes But yeah, they stopped that and then one of them one of the girls I know I know really kill vicki Maybe leave the rest of them alone, but there&#39;s a whole house full of terrible dudes, I guarantee 80 of those guys have roohipnol in their pockets It&#39;s seriously every one of them&#39;s got like a shitty like porno mustache they all</p>
                            <p>Dave (54:12.47)
                            This killer is killing the wrong people.</p>
                            <p>Dave (54:20.738)
                            You got a room full of shitty dudes.</p>
                            <p>Dave (54:28.118)
                            And they&#39;re all in their 40s.</p>
                            <p>Bryan White (54:34.823)
                            Yeah, they all just got off of work at the factory basically. Yep.</p>
                            <p>Dave (54:37.962)
                            Yeah, this is high 70s. This is not this is not glossy, shiny, you know, nagel 80s.</p>
                            <p>Bryan White (54:45.947)
                            Yeah, you gotta wait for 84 for like university and college students to start looking like college students in the movies.</p>
                            <p>Dave (54:51.17)
                            This is like washed out pinks and like Easter colors and tan everything.</p>
                            <p>Dave (54:58.102)
                            Fucking Earth tones. Yeah.</p>
                            <p>Bryan White (54:59.648)
                            like avocado. Yeah. So one of the girls gets pushed into the pool and all of them are like, oh my god, we gotta make sure that nobody turns on the pool lights. So this is the first one where they you know, we separate one of the girls so that she can beat her doom. So Stevie goes to the spooky basement to pull the breaker on the pool lights in case anybody tries to turn them on.</p>
                            <p>Bryan White (55:25.538)
                            And while she&#39;s down there, a creepy ass ball rolls out of the darkness to distract her. And then like, she does that thing that everybody does where they&#39;re like, who is that? You know, they like look down into darkness and then in silhouette, she gets killed. Yeah, pull, pull the fucking breaker and leave you. Yeah. Yep. That&#39;s me. That&#39;s slasher movie frustrations. For her troubles, she is stabbed.</p>
                            <p>Dave (55:37.704)
                            Mm-hmm. Stevie, it doesn&#39;t matter who it is. Get out.</p>
                            <p>Bryan White (55:54.638)
                            numerous times with the cane in silhouette. You don&#39;t actually see that one at all. And then we go back up to the place where the band is playing and they&#39;re doing some kind of fucking Sadie Hawkins thing in this scene.</p>
                            <p>Dave (56:08.258)
                            This scene is so weird because it&#39;s like you are a bunch of 45 year old men and maybe 20 year old ladies. And now you&#39;re doing this weird ladies choice sorority dance. That&#39;s like kind of like that weird wedding tradition. That&#39;s a little bit creepy. Like the like, what is this? What&#39;s going on here?</p>
                            <p>Bryan White (56:22.605)
                            Yeah.</p>
                            <p>Bryan White (56:25.422)
                            Yeah, this and also this had to be like an artifact of the time like a leftover of a previous era because There&#39;s a lot of slasher movies that use this Kind of set up for just like a body count pool like a victim pool The prowler does it my bloody valentine does it this one?</p>
                            <p>Dave (56:46.182)
                            But the prowler is like antiquated. It takes place in the like 40s.</p>
                            <p>Bryan White (56:50.318)
                            No, the Prowler takes place now, but a part of it takes place in the 40s. But My Bloody Valentine does it also. Like it seems to be a thing that was just like way more common, like probably after World War II, probably up until about the 70s. Cause like, we, I don&#39;t remember that. Like there were like schools, like middle school and high school dances. And I don&#39;t think they even do that anymore.</p>
                            <p>Dave (57:13.642)
                            My bloody Valentine just has a dance scene. It doesn&#39;t have anything weird about it.</p>
                            <p>Bryan White (57:17.69)
                            True, but I mean that that is kind of the whole like the whole all the killing sort of surrounds that that whole thing That&#39;s really the point. I&#39;m trying to make is is it&#39;s Yeah</p>
                            <p>Dave (57:24.662)
                            The dance, this very structured dance is very strange to me because like I did not, I did not have a traditional high school experience and I did not go to dances. But if I had, I think I would have been put off if they were this fucking weird.</p>
                            <p>Bryan White (57:41.616)
                            Yeah, it&#39;s it&#39;s god. It really is. It&#39;s very weird</p>
                            <p>Dave (57:43.51)
                            Cause it&#39;s basically just like, hey, here&#39;s compulsorily heterosexuality. Just smash these people together. Come on, get out there.</p>
                            <p>Bryan White (57:50.786)
                            Yeah, it&#39;s, it&#39;s, it&#39;s, it&#39;s, I kind of get the feeling like things are kind of moving back in that direction these days. Like everything is getting a little bit more conservative. It wouldn&#39;t be surprised like that like they&#39;re they&#39;re they&#39;re like outlying like girls from wearing suits to prom and shit like it this whole thing is is a fucking mess. It&#39;s really gross, you know, even though like I&#39;m sure that when you the people who went to see this movie were like, Oh, sure, we did that once, you know.</p>
                            <p>Dave (58:16.438)
                            without ever thinking, yeah, but it was pretty fucking weird when you did it too.</p>
                            <p>Bryan White (58:19.046)
                            But why? Yeah, yeah. So now we go back to the pool and this is where there&#39;s like three just rando dudes in their underwear about to jump into this gross pool that they&#39;re definitely going to get a disease from.</p>
                            <p>Dave (58:36.298)
                            And the only thing I can think is that they were trying, like how do we raise the specter of the pool again? Because how do you do it without it feeling kind of heavy handed? This is about as heavy handed as you could possibly be. But like, they were just like, it needs to be a threat again. How do we do that? It&#39;s like, I don&#39;t know, maybe a bunch of dudes in their underwear want to get in the water. Why?</p>
                            <p>Bryan White (58:43.78)
                            Yeah.</p>
                            <p>Bryan White (58:50.367)
                            Right, and this is the thing like</p>
                            <p>Bryan White (58:58.026)
                            Right because the breaker did not get pulled the lights in the pool are still sort of an ongoing problem And this is the thing about the movie is I actually feel like this movie&#39;s It&#39;s not great at pacing But there are other movies of the era that definitely drag in places and are much more egregiously Like slow this one kind of moves along and like nothing really happens without a reason and so this is just to sort of remind you</p>
                            <p>Bryan White (59:27.502)
                            Stevie got killed, she didn&#39;t pull the breaker because now they turn the lights on and all the girls run down there. And there&#39;s that one fat guy jumps into the pool and announces, I&#39;m a sea pig. And that&#39;s when all the girls run down and they turn on the lights in the pool.</p>
                            <p>Dave (59:38.322)
                            Sea pig. But then he looks at them and he&#39;s just like, I&#39;m a sea pig? Question mark? They don&#39;t even know what you&#39;re talking about.</p>
                            <p>Bryan White (59:46.828)
                            Yeah, just a little bit of college college hijinks. Who even are you, Mr. C. Pig? Yeah, but you couldn&#39;t pay me to fucking jump in that pool. But what we find out.</p>
                            <p>Dave (59:57.634)
                            That&#39;s what I mean. It&#39;s like, why would they look at that and be like, you know what&#39;s gonna be really funny is if we get into our underwear and we get in this fucking disgusting pool, that&#39;ll be real hilarious. Will it?</p>
                            <p>Bryan White (01:00:07.638)
                            Yep, yep. But but yeah, but the curtain on the pool lights and what we find is that Mrs. Slater&#39;s body&#39;s not</p>
                            <p>Bryan White (01:00:19.455)
                            So now, again, I think this is another scene where Catherine&#39;s like, this is out of control. I&#39;m going to call the authorities. And Vicki is like, over my dead body, you will.</p>
                            <p>Dave (01:00:29.226)
                            Oh yeah, yeah, we end up back in the kitchen for this one.</p>
                            <p>Bryan White (01:00:32.098)
                            Yep, and this is when Morgan kind of tearfully wanders off and she goes to</p>
                            <p>Dave (01:00:38.23)
                            I&#39;m sorry, are you did you just skip over the greatest part of the movie? When they&#39;re all in the kitchen and they&#39;re like, I you can&#39;t do that, we call the police that will ruin our lives. And my note just says that Katie needs to take about 20% off the acting at this point.</p>
                            <p>Bryan White (01:00:42.952)
                            Did I?</p>
                            <p>Bryan White (01:00:56.151)
                            She&#39;s definitely like soap opera acting in a conventional Hollywood picture.</p>
                            <p>Dave (01:00:59.05)
                            Yeah, yeah, she is. She&#39;s doing it for the back rows at this point. And one of them says something about like, well, if she&#39;s still alive, what if she&#39;s still alive? And then Morgan Morgan says she stops and in the most earnest way possible, says, how do we know she is alive? And it is the weirdest fucking moment in any movie ever.</p>
                            <p>Bryan White (01:01:10.773)
                            Alright!</p>
                            <p>Bryan White (01:01:21.686)
                            In my notes says.</p>
                            <p>Bryan White (01:01:26.842)
                            My note just says Morgan&#39;s supposed to be the stupid one, right?</p>
                            <p>Dave (01:01:29.87)
                            Well, it this so this line is you obviously do not know this line is kind of iconic. Yeah, it is. It is for some weird reason, because it&#39;s it&#39;s not a matter of acting. And the the inflection points are right. But it&#39;s like they&#39;re overemphasized in a way that&#39;s like she was trying to emphasize the how do we know she is alive?</p>
                            <p>Bryan White (01:01:36.523)
                            I did not.</p>
                            <p>Bryan White (01:01:55.969)
                            Right, yeah.</p>
                            <p>Dave (01:01:56.086)
                            but it&#39;s the tone of voice that&#39;s just like, why do you sound like Kermit the Frog? Why are you speaking so slowly? What the hell is going on?</p>
                            <p>Bryan White (01:02:02.747)
                            You know the thing is is They don&#39;t really give Morgan much to do in the movie right like she doesn&#39;t have a whole lot of spoken lines So maybe she was like, okay She&#39;s yeah her whole thing in this movie is she&#39;s there to basically do that one line and then undress She uh, so yeah, so now she is that I don&#39;t know if this is her room or this is mrs. Slater&#39;s room</p>
                            <p>Dave (01:02:10.154)
                            Yeah, something tells me she&#39;s not a great actor.</p>
                            <p>Dave (01:02:19.438)
                            And thank God. For both, I guess.</p>
                            <p>Bryan White (01:02:29.026)
                            because this is where that attic door is. And let&#39;s think of an attic door in like every closet in the house. But she&#39;s in there singing like the sorority song, like very sadly, very tearfully, as she like takes all these like clothes off the rack.</p>
                            <p>Dave (01:02:41.398)
                            Oh yeah, because they&#39;re supposed to make it look like, she&#39;s like, go up, Vicky tells her to go upstairs and make it look like Mrs. What&#39;s-her-face has, is like packed and left. So she&#39;s in her room.</p>
                            <p>Bryan White (01:02:50.746)
                            Gotcha. Okay, so that&#39;s that&#39;s why she&#39;s she&#39;s up there doing she&#39;s in her room. And so as she&#39;s doing this, the attic door is like opening a little bit, a little bit more and a little bit more and then we switch to like a POV of the attic door opening and something coming at her descending on her. We cut back. We, we, we find out that when all the girls are up there that this is Mrs Slater&#39;s body which</p>
                            <p>Bryan White (01:03:18.91)
                            is still wrapped in towels, in spite of the fact that we saw, like, you know, like that shit I pointed out earlier, where, like, somebody had unwrapped her, basically. Because we&#39;re supposed to think that it&#39;s her who&#39;s stomping around, killing all these girls still. We get a little bit more cheap nudity right before Morgan gets it.</p>
                            <p>Dave (01:03:39.545)
                            And this is when she&#39;s got she has the like the toy. What is that? The Jack in the Box thing. And she she is so genuinely delighted by this thing.</p>
                            <p>Bryan White (01:03:43.426)
                            Yeah. Yep.</p>
                            <p>Bryan White (01:03:48.832)
                            I know!</p>
                            <p>Dave (01:03:49.354)
                            And then she seems so surprised when the thing pops up out of the thing that&#39;s a jack-in-the-box and it&#39;s like, bitch, what did you think was gonna happen at the end of the song? Like, this is not a new concept.</p>
                            <p>Bryan White (01:04:00.458)
                            I know. Yep, that&#39;s our Morgan. She&#39;s a little simple, but.</p>
                            <p>Dave (01:04:03.574)
                            Oh, man. And she puts on like this kind of sexy Bolero outfit to go to bed, I guess.</p>
                            <p>Bryan White (01:04:10.126)
                            Yep. Maybe, I mean, the party is still in full swing down there. Like, I don&#39;t know. I mean, maybe like a dead body dealing with this, like this conspiracy has got to put it on you. So yeah, she puts on some sexy shit just to get murdered in it. She gets run through again by the Kane from behind. The deadliest fucking Kane in the world. So now the plan is they&#39;re going to move Mrs. Slater&#39;s body to a nearby cemetery. Terry.</p>
                            <p>Dave (01:04:28.718)
                            That was a shame.</p>
                            <p>Bryan White (01:04:40.586)
                            So to get her there they need to get it to a car and they argue over whose car it&#39;s gonna be Before I can&#39;t I can&#39;t remember Diane Or Liz one of them goes off to get it</p>
                            <p>Dave (01:04:51.601)
                            I think it&#39;s Liz. Well, so it&#39;s Liz. It&#39;s Liz&#39;s car. And she throws her she throws Diane the keys and she&#39;s just like, I just got it or something.</p>
                            <p>Bryan White (01:04:55.302)
                            she keeps saying like, oh, I just yeah, don&#39;t yeah, don&#39;t don&#39;t spoil it. Because what I want to, I want to get to it because the the revelator, the reveal, the reveal of the car is like one of my favorite parts of the entire movie. So what the girls do is they, they, Genie&#39;s like here, will put her in this and it&#39;s like a dumpster. So they put her</p>
                            <p>Dave (01:05:04.185)
                            OK. Because when I got there, I was like, wait a minute, what?</p>
                            <p>Dave (01:05:18.786)
                            They point to it and she goes, Vicky goes, oh, that&#39;s perfect. How is a giant dumpster perfect?</p>
                            <p>Bryan White (01:05:25.668)
                            I know I would have like even just even putting aside just the fact that they&#39;re they&#39;re dealing they they murdered this woman or rather Vicki murdered her and they&#39;re covering up I would have real problems like putting a woman&#39;s body in the garbage just I don&#39;t know but like this is not a genius proposition so</p>
                            <p>Dave (01:05:44.45)
                            But I think that&#39;s kind of what makes them all sort of likeable is like, these are bumbling idiots from the moment this movie begins and it never stops. They really are.</p>
                            <p>Bryan White (01:05:51.751)
                            they&#39;re the they&#39;re the keystone cops keystone cops of murder. Yeah, but um so now we break Katherine has gone into the attic where you know the where the</p>
                            <p>Dave (01:06:05.558)
                            with like a giant old timey candlestick?</p>
                            <p>Bryan White (01:06:09.159)
                            Yeah, it&#39;s like in Butcher Baker where she&#39;s got like this candelabra that&#39;s just going through that.</p>
                            <p>Dave (01:06:13.051)
                            Crap! Flashlight! It&#39;s 1981!</p>
                            <p>Bryan White (01:06:15.762)
                            it&#39;s not like they don&#39;t have them either because we&#39;ve seen like them walking around with flashlights, but She finds that like the the attic is like, um, it is toys and shit all over the place It&#39;s made up like a child lives there And she finds a birthday card made out to someone named eric signed lovingly from mother Um, she also finds checkoff&#39;s harlequin costume, which is an adult adult-sized costume of like a</p>
                            <p>Dave (01:06:37.966)
                            Yeah. Which is also it&#39;s the Harlequin costume is a recreation of what&#39;s in the Jack in the Box.</p>
                            <p>Bryan White (01:06:44.81)
                            Right, it matches the jack in the box. So.</p>
                            <p>Dave (01:06:46.826)
                            Which I feel like that has to be a custom piece. And this lady, she paid for that? She&#39;s just a house mother. And I don&#39;t mean to say that dismissively. I don&#39;t know how much money they make, but I&#39;m guessing it&#39;s not enough for a custom Harlequin costume. For an adult baby? I don&#39;t even know what that costs.</p>
                            <p>Bryan White (01:06:53.593)
                            I know.</p>
                            <p>Bryan White (01:06:55.96)
                            Unless...</p>
                            <p>Bryan White (01:06:58.65)
                            maybe she made it herself, you know, for a full grown baby. She finds it there, hang in there. And like I said, it matches the Jack in the Box. And this is where here comes Peter again. So as this is happening, Peter has kind of come and gone throughout the situation where, like when he first shows up, Catherine is very distressed. This is right after the murder has happened. And he&#39;s like,</p>
                            <p>Dave (01:07:13.43)
                            Ugh.</p>
                            <p>Bryan White (01:07:27.422)
                            Maybe this is a bad time. Hey, this is like weird for me to I&#39;ll leave and and Catherine&#39;s like Yeah, right. This was follow your instincts bro and Catherine&#39;s like no Yeah, and so Yeah, and so she&#39;s like no no no stay please stay and it&#39;s like all right, you know like this But then he&#39;s he&#39;s shown up a couple of times. They dance a little bit. He&#39;s like, hey, i&#39;m having a great time Ain&#39;t you having a great time too?</p>
                            <p>Dave (01:07:31.703)
                            Okay, good. Yes, Peter.</p>
                            <p>Dave (01:07:36.05)
                            Your date has been absent all night long. I think that pretty much tells the story.</p>
                            <p>Bryan White (01:07:54.018)
                            He&#39;s not scuzzy, he&#39;s not sleazy. He seems like an okay guy. He&#39;s just fucking feckless. He&#39;s just, he&#39;s no idea. And...</p>
                            <p>Dave (01:08:02.39)
                            Yeah, he just sucks. Like, I feel like if I was hanging out with Peter, every time I turn around, like, Peter, what are you still doing here? Like, it is two o&#39;clock in the morning. I went to bed two hours ago. Why are you in my living room?</p>
                            <p>Bryan White (01:08:12.39)
                            Because, because I know now he, this is like the second time he sort of just lurched into the scene and this will be the second time that she has said, you need to go, you need to just get out of here. And he keeps fucking showing up.</p>
                            <p>Dave (01:08:26.41)
                            And he says his lines as he walks in the room, he&#39;s like, Oh, this is a neat room. It&#39;s like, okay, no, it is not a neat room, Peter.</p>
                            <p>Bryan White (01:08:32.495)
                            This is a terrifying room. This is like one of the rooms from the fucking, this is like something out of a nightmare on Elm Street. Yeah. Yeah, and like, he&#39;s like, hey, oh, you&#39;re starting riding on one of the toys. He&#39;s like, this used to be my favorite. And again, she says, get the fuck out of here.</p>
                            <p>Dave (01:08:39.168)
                            This is where a killer lives, clearly.</p>
                            <p>Dave (01:08:50.326)
                            Yeah, she&#39;s basically like, Peter, I think you need to go home. And my note actually says, Jesus, Peter, go home.</p>
                            <p>Bryan White (01:08:57.506)
                            because he&#39;s gonna keep showing up. They both notice a dead bird in a birdcage, which.</p>
                            <p>Dave (01:09:03.218)
                            Oh, so I got a couple questions about this bird. That bird is very clearly recently dead. So if there&#39;s a bird in the attic, birds make a lot of noise. I don&#39;t know if you&#39;ve ever been outside before, but.</p>
                            <p>Bryan White (01:09:08.578)
                            Yeah, because it&#39;s all like red blood.</p>
                            <p>Bryan White (01:09:16.366)
                            Yeah, they hadn&#39;t noticed this. Also, occasionally. Also, birds live a long time. So that bird has presumably been there for a while.</p>
                            <p>Dave (01:09:24.106)
                            Yeah. Yeah. So my question is, how have they never heard it? Who is feeding it?</p>
                            <p>Bryan White (01:09:28.686)
                            Yep. Yeah. Yeah. Because, yeah, as we find out, Eric&#39;s not there all the time. He&#39;s only there in the summers. But, yeah, that&#39;s right. Yeah. So now that Diane.</p>
                            <p>Dave (01:09:38.99)
                            Because he&#39;s in the Indiana Jones facility the rest of the year. Where they all talk in hushed tones about mistakes made in the past.</p>
                            <p>Dave (01:09:52.894)
                            Yeah, like I want a fucking wizard on the side of this van.</p>
                            <p>Bryan White (01:09:57.402)
                            It may as well have she&#39;s got like a CB radio that has like a full</p>
                            <p>Dave (01:10:01.866)
                            Yeah, there&#39;s a fucking sight gag with the CB radio falls down and spooks it like, why is this girl, this like hot young sorority girl, driving this fucking van?</p>
                            <p>Bryan White (01:10:10.766)
                            This is a van. This van belongs to a man in his 30s who hangs out at like one particular discotheque in town and still gets ass with it. Like this van does not belong in the possession of like a.</p>
                            <p>Dave (01:10:25.726)
                            Either that or like a man in his 50s who has or possibly maybe has not kidnapped a woman.</p>
                            <p>Bryan White (01:10:33.79)
                            Yeah, when he does that, he goes in the back and he just like makes like ships and bottles and shit like it&#39;s it&#39;s such a fucking weird artifact like it&#39;s very clearly. Okay, we need a car for Liz and they&#39;re like, can we borrow a car from somebody and one of the PAs went like yeah, a buddy of mine has a has a car I&#39;ll be right back and he shows up with this thing that would be perfect in like</p>
                            <p>Dave (01:10:34.794)
                            But odds are good that he has.</p>
                            <p>Dave (01:10:56.458)
                            And they were like, we don&#39;t have any more time. We&#39;re going with this thing. But she talks about it like it&#39;s a fucking trans am. Like she&#39;s just like, no, this is my teatop hot fucking car. And it is not it is not that at all.</p>
                            <p>Bryan White (01:10:59.706)
                            Yeah</p>
                            <p>Bryan White (01:11:03.734)
                            It may... This thing might as well have been...</p>
                            <p>Bryan White (01:11:11.598)
                            No, no, but oh my God, the revelation of this van, I went, this was like the wedgie picking scene in Final Exam where I had to like, I had to like go back to the original scene to make sure that this van like belonged to one of the girls because like, it just doesn&#39;t make sense in the context of the rest of the movie, it&#39;s so.</p>
                            <p>Dave (01:11:34.946)
                            See, this is how this movie gets you. Is the more you talk about it, you&#39;re like, you know what, this movie&#39;s fucking great.</p>
                            <p>Bryan White (01:11:39.174)
                            Thank you.</p>
                            <p>Bryan White (01:11:42.902)
                            Oh, it&#39;s so great. It&#39;s just, it&#39;s the peak moment of the entire movie. I cherish this scene.</p>
                            <p>Dave (01:11:47.466)
                            Yeah. Yeah, I think everything from this point on is why I love this movie.</p>
                            <p>Bryan White (01:11:54.17)
                            It does it does it you know what it does get way better because we&#39;re we are kind of rounding the the corner into the third act like the murders are ramping up because this is the scene I think that&#39;s Liz who goes to get the car she gets killed by some unseen assailant above her or maybe it&#39;s Diane</p>
                            <p>Dave (01:12:09.714)
                            Oh, no, it&#39;s Diane because she gets stabbed through the sunroof or whatever in the hand. And the hand is like clearly someone just sort of made this thing out of clay because it is like it is. No, nothing.</p>
                            <p>Bryan White (01:12:16.483)
                            Yeah.</p>
                            <p>Bryan White (01:12:21.442)
                            Yeah, yeah, because there&#39;s no blood. There&#39;s no blood. Also, when the guy in the bushes, who&#39;s like, you get stabbed in the neck, like they went to all that trouble to make that like pretty good like mold of this guy&#39;s body. And there&#39;s no blood when he gets stabbed. Like surely they could have done something.</p>
                            <p>Dave (01:12:39.63)
                            That&#39;s because they use it all for the head and the toilet.</p>
                            <p>Bryan White (01:12:41.726)
                            Yeah, yeah, yeah. So the girls are now pushing the dumpster towards the garage where the van is, but they...</p>
                            <p>Dave (01:12:50.38)
                            Which this is where the movie you&#39;re like, what is going on? Why are they pushing a dumpster with a body in it? What&#39;s happening?</p>
                            <p>Bryan White (01:12:57.364)
                            Couldn&#39;t she have just driven up to get the body? Or, I don&#39;t know, something else, but I don&#39;t know why they did this.</p>
                            <p>Dave (01:13:03.858)
                            Oh, because their whole plan, I don&#39;t think you mentioned this, their whole plan is they&#39;re going to bury the body in the cemetery.</p>
                            <p>Bryan White (01:13:10.274)
                            Yeah, yeah, because that&#39;s the thing is that there&#39;s a cemetery right nearby. They&#39;re gonna go they&#39;re gonna do it They need to move it to the van to get to the cemetery, but this whole dumpster plan is very convoluted But they push it into a cop&#39;s car and he almost in the scene This is a real they could have lost this scene and have have been have been richer for it But like he almost opens eight. I guess it&#39;s just tension building or whatever cop almost opens it to find the body</p>
                            <p>Dave (01:13:29.067)
                            Yeah.</p>
                            <p>Bryan White (01:13:38.05)
                            just before he turns the lights on and gets out of the car, Jeannie runs off.</p>
                            <p>Dave (01:13:42.483)
                            Yeah, this is like another Sea Pig moment where you&#39;re just like, ah, you&#39;re almost caught. Ah!</p>
                            <p>Bryan White (01:13:46.242)
                            Yeah, yeah. So Jeannie goes, she&#39;s attacked on her way into the house by the killer who now, like he gets a couple of shots on her and she gets away, but also the killer drops this like emergency bracelet, like, you know, if in an emergency call this number sort of thing, which is, it&#39;s a prop that&#39;s set up at the hospital earlier on in the movie, like way, way, way earlier in the movie.</p>
                            <p>Bryan White (01:14:17.589)
                            But that just that just hits the ground. Jeannie.</p>
                            <p>Dave (01:14:19.365)
                            Yeah, the emergency bracelet is about to go off in the third act.</p>
                            <p>Bryan White (01:14:22.454)
                            Yeah, so Jeannie gets away. She runs into Catherine, who&#39;s like, now Jeannie&#39;s like traumatized by this. She&#39;s a little cut up. Catherine leaves her to get help, of course. And then she&#39;s chased by the killer who comes into the sorority house. She runs into a bathroom and hides into stall, you know, scene that plays out almost exactly like the restroom scene in Maniac. See our episode about Maniac.</p>
                            <p>Dave (01:14:44.13)
                            Mm hmm. I think there&#39;s also there&#39;s a scene in prom night that&#39;s very similar to this.</p>
                            <p>Bryan White (01:14:50.478)
                            Yeah, and the killer of course finds her she&#39;s she&#39;s sitting like on top of the toilet with her teeth with her feet up And she&#39;s got a big fucking knife that she brought was she&#39;s ready This is a killer finds her and she lunges at them But they get the best of her and they basically pin her to the wall with a knife. There&#39;s a huge like blast of blood and like this Yeah</p>
                            <p>Dave (01:15:11.277)
                            Yeah, this is the only death that&#39;s actually pretty violent.</p>
                            <p>Bryan White (01:15:14.754)
                            Yeah, like I said, like this is I think this is probably the the most like violent and most explicit of all the of all the kills in the entire movie. But here&#39;s Peter again, we cut back to to Catherine, who&#39;s now in the kitchen, she&#39;s going to call the cops and Peter&#39;s like, Oh, well, they&#39;ll want to talk to your house mother, right. And that&#39;s enough to like stop her in her tracks and be like, Oh, well, yeah, maybe everybody&#39;s back at the party or maybe they all left or something.</p>
                            <p>Bryan White (01:15:43.774)
                            So she&#39;s trying to like not address the house mother situation, but once again, she says Peter Go the fuck home</p>
                            <p>Dave (01:15:51.267)
                            Yeah, she explicitly says, Peter, I think you should go home and he looks so hurt by this. And it&#39;s like, dude, she has been your date for 15 minutes tonight. You have not seen her. You have spent more time with other people at this party than you have with your actual date. You can&#39;t look hurt.</p>
                            <p>Bryan White (01:16:05.514)
                            I get the feeling that maybe maybe there maybe there was a whole like subplot involving Peter that just got cut during during post production, and all they did was was just leave these scenes in. Because yeah, but here&#39;s here&#39;s a spoiler, he doesn&#39;t go home. Nope, we&#39;re dealing with Peter. We&#39;re dealing with Peter for a while. But</p>
                            <p>Dave (01:16:13.506)
                            Thank fucking God, because he sucks.</p>
                            <p>Dave (01:16:21.517)
                            Nope.</p>
                            <p>Bryan White (01:16:29.074)
                            Like right after that happens, she stops outside now, she finds that like emergency bracelet that she recognizes as Mrs. Slater&#39;s. And so she calls the number on it. And it&#39;s the doctor from the Top Men Hospital earlier and the sort of establishing scene.</p>
                            <p>Dave (01:16:45.048)
                            And like this just this she calls it and it just goes to his phone like his desk phone</p>
                            <p>Bryan White (01:16:49.842)
                            Apparently, yeah, and he&#39;s and also it&#39;s like we&#39;ve the scene the scene with the cop establishes that it is like one it is quarter to 2 a.m. And This guy is still working Because he answers and he comes running and right when he is when he arrives who&#39;s that in his car passed out? But it&#39;s fucking Peter again So he shows up and</p>
                            <p>Dave (01:16:53.726)
                            And he&#39;s working at midnight.</p>
                            <p>Bryan White (01:17:19.638)
                            It&#39;s exposition time, basically.</p>
                            <p>Bryan White (01:17:24.29)
                            What turns out, what we find out is, and I&#39;ll tell you what, this misdirect actually worked on me the first time. The whole time I was like, who is the killer? Is it actually, is Mrs. Slater really alive? Is it?</p>
                            <p>Dave (01:17:38.225)
                            Oh, because they keep up the Is Mrs. Slater Alive thing until like the end of the-</p>
                            <p>Bryan White (01:17:43.902)
                            Yeah, yeah, because there is a scene. No, no, there is a scene coming up where we finally firmly established that she is dead. She is 100% dead.</p>
                            <p>Dave (01:17:44.79)
                            for reasons that don&#39;t make any sense.</p>
                            <p>Dave (01:17:54.21)
                            But even then, he says, she&#39;s still alive.</p>
                            <p>Dave (01:18:00.714)
                            Yeah, I don&#39;t know why he says it, but.</p>
                            <p>Bryan White (01:18:01.483)
                            Oh, he does? Oh. Oh, because yeah, they unwrap her and it&#39;s her body still. Yeah, but the first time that I watched this I had no idea it just kind of goes to the movie&#39;s quality in the way that it really is kind of like pretty snappy in its storytelling and or either that or I&#39;m just a really credulous movie viewer and I&#39;ll just go along with anything. But like when I&#39;m sitting here thinking like</p>
                            <p>Dave (01:18:07.311)
                            She&#39;s clearly dead.</p>
                            <p>Bryan White (01:18:28.714)
                            After she finds the birthday card, I&#39;m like, oh, so is Mrs. Slater crazy or is there really this killer? That&#39;s when the ice started to thaw a little bit. But like right up to this point, I was like, oh, shit. Okay, so this Eric is really the killer. So hey, good on you, House and Sorority Row. You.</p>
                            <p>Dave (01:18:47.315)
                            I would have welcomed an old lady killer. And I mean like a killer who is an old lady, not a killer of old ladies.</p>
                            <p>Bryan White (01:18:49.946)
                            I, oh yeah, oh yeah, yeah. But he gives her the rundown. There was a tough delivery, but she actually has the baby who was the product of some kind of fertility experiment that&#39;s like controversial and like done on the slide by this doctor who is then like, he was born with certain abnormalities and he doesn&#39;t go into detail. And I feel like they really slept on that because when Eric,</p>
                            <p>Bryan White (01:19:19.082)
                            is sort of like properly introduced to the story. We only really get a fleeting glimpse of him. It&#39;s almost like the end of final exam, like over again, where like Eric is really red herring. It doesn&#39;t fucking matter.</p>
                            <p>Bryan White (01:19:38.942)
                            It works in the context of the story. It would have been nice if they just gave us a little bit more, but it turns out he&#39;s some... It&#39;s just...</p>
                            <p>Dave (01:19:43.688)
                            Except in final exam it is literally nobody. At least in this it&#39;s like you know who he&#39;s supposed to be, it&#39;s just who he is doesn&#39;t</p>
                            <p>Bryan White (01:19:53.158)
                            Yeah, yeah, it&#39;s a little Jason Voorhees-ish. Maybe that&#39;s where they got the beats from. Because he&#39;s supposed to be, when we do see him, we see him for literally a second. And there&#39;s something about his appearance that&#39;s off, but you really can&#39;t tell, because he&#39;s kind of hidden in the gloom. Yeah. Yeah, I mean, they do go out of their way to.</p>
                            <p>Dave (01:20:10.846)
                            And because they didn&#39;t have any money, so they really don&#39;t want you to look at it too closely. Because they do eventually show him and it&#39;s like, oh, come on.</p>
                            <p>Bryan White (01:20:20.862)
                            Well, they put him in the Harlequin costume. Yeah, well, that&#39;s what I was saying is you get that like one second glimpse of him, and he&#39;s just again like, there&#39;s something going on.</p>
                            <p>Dave (01:20:22.814)
                            No, you see his face very briefly.</p>
                            <p>Dave (01:20:30.218)
                            It&#39;s like when you see Michael Myers face, you&#39;re like, oh, it&#39;s just a dude.</p>
                            <p>Bryan White (01:20:33.698)
                            Yeah, yeah. Yep. And like in the recent ones where they pull his mask off and he&#39;s just like some old guy with male pattern balding and I&#39;m like, fuck, man, I know how it feels, bro. But, um, yeah, so this is a big info dump where they&#39;re just like, Eric is real. He, he lives at the institution. Throughout the year, but when the house is closed and everybody goes home, Mrs. Slater comes and gets him and they they</p>
                            <p>Bryan White (01:21:01.518)
                            he lives in the house, but she still keeps him in the attic like, like.</p>
                            <p>Dave (01:21:03.842)
                            Which look, I don&#39;t wanna call anybody out for anything here, but Mrs. Slater&#39;s really fucking judgmental throughout this whole movie. She is also being inappropriate. It&#39;s not okay for her to be bringing her crazy son to live in the weird murder attic. So, you know. I&#39;m not gonna lie, I&#39;m not gonna lie. I&#39;m not gonna lie.</p>
                            <p>Bryan White (01:21:20.47)
                            Oh, sure. Yeah. Oh, yeah. It&#39;s totally it&#39;s like it&#39;s almost it&#39;s like that treehouse of horror where there&#39;s like a mutant version of Bart that lives in the Simpsons Attic. Yeah, so that&#39;s that&#39;s.</p>
                            <p>Dave (01:21:31.946)
                            I&#39;m disappointed and terrified that you looked up there.</p>
                            <p>Bryan White (01:21:38.603)
                            But yeah.</p>
                            <p>Dave (01:21:39.438)
                            We have three children Homer! Three!</p>
                            <p>Bryan White (01:21:44.548)
                            But yeah, so now they go down by the pool again and now Catherine finds a bunch of this is like every slasher movie where there&#39;s the tableau or the killer like leaves all the bites on. Yeah, or, or does he that&#39;s the thing.</p>
                            <p>Dave (01:21:55.554)
                            But then he, so he calls the police. But see, the thing is, it is pretty clear that he is actually talking to someone. Otherwise, this is a very elaborate ruse.</p>
                            <p>Bryan White (01:22:06.554)
                            Okay.</p>
                            <p>Bryan White (01:22:09.206)
                            Right because because they don&#39;t show up as a matter of fact he tells her the police aren&#39;t coming Yeah but um</p>
                            <p>Dave (01:22:13.014)
                            He says they&#39;re not coming. But he is very clearly calling and talking to some.</p>
                            <p>Bryan White (01:22:18.978)
                            Yeah, and then we cut back to the to the cemetery where the girls have taken the body to the cemetery and they start to dig what they what they what they find is they find a freshly dug grave for a body that&#39;s going to go in there eventually and their whole plan is to dig it a little deeper and throw throw Mrs. Slater&#39;s body in there and then the the coffin for this that&#39;s intended for this hole will go in there. And this is</p>
                            <p>Dave (01:22:24.474)
                            I love this part.</p>
                            <p>Dave (01:22:40.222)
                            I&#39;ll tell you what though, that&#39;s the most ingenious part of their plan.</p>
                            <p>Bryan White (01:22:43.162)
                            That&#39;s actually a pretty good idea. No one will think of it. But yeah, so, so, yep, so Vicky stays in the hole while Liz, I think is the one who goes back to the car and she gets, she&#39;s in the front seat. She gets her throat cut straight across.</p>
                            <p>Dave (01:22:49.186)
                            Queens, I&#39;m telling you, both of them.</p>
                            <p>Dave (01:23:01.706)
                            Yeah, this is the this is the side gag with the thing. The CB radio falls down in price. Yeah.</p>
                            <p>Bryan White (01:23:04.81)
                            Oh yeah, it keeps like falling on her end. She keeps trying to put it back up and it keeps falling. And she gets, yeah, they drag the sharp part of the cane topper across her throat, no blood. And now it&#39;s Vicki&#39;s turn, finally to get killed. And she gets jammed up a bunch, like stabbed up, stabbed in the back, stabbed in the front, stabbed in the-</p>
                            <p>Dave (01:23:26.582)
                            Because then they show it like this lingering shot of her because she&#39;s still like in the hole, right?</p>
                            <p>Bryan White (01:23:32.418)
                            No, she comes out because she comes out and she goes up to see what Liz was doing. And so like she rolled like that&#39;s the part where she rolls across the surface of the van like smears blood on it like it&#39;s Texas Chainsaw Massacre. And.</p>
                            <p>Dave (01:23:43.374)
                            Because when they show her again, it&#39;s like someone savaged this body.</p>
                            <p>Bryan White (01:23:48.73)
                            Yeah, she gets hit a bunch of times and then she gets finally stabbed through the eye with it. And then the killer throws the bodies in the hole.</p>
                            <p>Bryan White (01:24:00.218)
                            Catherine and the doctor go there to the cemetery, find the bodies in the hole, of course. And then this is when they look in the van and they find Mrs. Slater&#39;s body like in there and they unwrap it and she&#39;s dead as fuck in there. Like that.</p>
                            <p>Dave (01:24:13.066)
                            And this is when he says she&#39;s still alive. Or maybe he says he&#39;s still alive. I don&#39;t know. It doesn&#39;t really matter. Nothing about this movie matters, really. You know.</p>
                            <p>Bryan White (01:24:16.09)
                            Uh yeah. It could be he could be talking about Eric really is is no. So in the way back the doctor sedates Catherine like by surprise he like he stabs her with you know a needle full of something and this is when we begin the like trippy Mario Bava scene.</p>
                            <p>Dave (01:24:35.422)
                            He says, he says, it&#39;s a mild sedative.</p>
                            <p>Bryan White (01:24:38.634)
                            mild my ass. She is tripping balls. This scene is like last year at Marion Badd and fuck, so some, I don&#39;t know, name some.</p>
                            <p>Dave (01:24:40.295)
                            But also Mescaline, what happened?</p>
                            <p>Dave (01:24:51.822)
                            It&#39;s like when Homer eats the chili at the Chili Fest. It&#39;s that wild.</p>
                            <p>Bryan White (01:24:57.663)
                            Oh, yeah. And like, there&#39;s like some carnival of souls touches in this because like</p>
                            <p>Dave (01:25:01.614)
                            Oh yeah, no, this goes hard into like Cardinal of Souls and The Innocents, like it has a real 60s edge to it.</p>
                            <p>Bryan White (01:25:08.622)
                            Yeah, because so like what the doctor does is he sits her down in a chair and then he goes through the house and essentially like locks all these doors and sets it up like a kill box. And he explains to her like, we got to get Eric and you&#39;re the bait. And so this is the part where she&#39;s like looking through and this is this is artfully done it&#39;s a very look at this was like one of my favorite parts of the whole movie, where, like she&#39;s looking out and there&#39;s like colored lights and it&#39;s the whole like Baba red and green contrast that they do a lot of and like.</p>
                            <p>Dave (01:25:26.882)
                            Yeah, this part&#39;s really cool.</p>
                            <p>Bryan White (01:25:34.874)
                            her dead friends are there and Mrs. Slater is there and the cane is spinning on its own and like it&#39;s.</p>
                            <p>Dave (01:25:39.278)
                            But it also has like a kind of a very kind of dark shadows edge to it, where it&#39;s like it looks a little cheap, but it&#39;s really effective.</p>
                            <p>Bryan White (01:25:45.526)
                            Yeah, yeah, yeah, it&#39;s yeah, it&#39;s a really, really great scene. And it&#39;s it&#39;s like, like, and it&#39;s also got a touch of like the acid eaters or reefer madness or something like that, where it&#39;s it&#39;s it&#39;s it&#39;s great. So. Once again, here comes Peter, who sees, see, sees, sees Catherine in the chair and he goes running at her and the doctor shoots him with a tranquilizer dart.</p>
                            <p>Dave (01:26:11.19)
                            Tell you what, if it were not for Peter, they would have had the other dude.</p>
                            <p>Bryan White (01:26:15.69)
                            Yep. Fucking Peter, man. This fucking guy. They honestly, they should have killed him just for laughs.</p>
                            <p>Dave (01:26:22.082)
                            Peter, nobody wanted you here to begin with. Clearly Catherine didn&#39;t.</p>
                            <p>Bryan White (01:26:24.194)
                            Yeah, he has had the worst. He will for the rest of his life, he will never go on another blind date. Catherine is sort of uses this moment of distraction to sort of get up and run down the hall. There&#39;s a really awesome zoomed like Dolly zoom shot as she&#39;s running where like the hall seems to move as she&#39;s going through it. And she gets into Vicky&#39;s room and she finds the gun. The doctor is looking for her and he ends up getting his ass killed by Eric.</p>
                            <p>Bryan White (01:26:52.506)
                            who like hits him a bunch of times with the cane and then he hits him like up underneath the chin, which sends him like reeling over the railing and he falls to his death.</p>
                            <p>Dave (01:27:01.397)
                            I believe this is when you see Eric&#39;s face.</p>
                            <p>Bryan White (01:27:03.53)
                            Right, so now Catherine has the gun and she goes out to the same railing and she looks over and she sees the doctor&#39;s body and she sees Eric standing there just kind of looking at the body. She tries to shoot him with the gun, but like she obviously doesn&#39;t know how it works, like safety mechanism and all that stuff. And this gets Eric&#39;s attention and for just a split second he looks up and we don&#39;t see him, well we talked about it, we don&#39;t see him very well, but something&#39;s off about his appearance.</p>
                            <p>Dave (01:27:30.321)
                            He has like, it&#39;s like a, put like a clay mask on him.</p>
                            <p>Bryan White (01:27:33.498)
                            Kinda, yeah.</p>
                            <p>Dave (01:27:35.222)
                            Like it&#39;s kind of lumpy looking, but that&#39;s about it.</p>
                            <p>Bryan White (01:27:38.21)
                            Yeah, there&#39;s and honestly, this is like I said, like I think I really wish that they had like mind a little bit more out of the sort of medical experimentation, but like you could have really gotten some mileage out of that. And maybe sacrificing a little bit of the hoot on an angle for it. But, they were really rounding the corner towards the end, Catherine hides in the bathroom and she finds poor genie&#39;s head in the toilet, which is my favorite shot of the entire movie, because it&#39;s not like a dummy head it&#39;s like the</p>
                            <p>Bryan White (01:28:07.29)
                            They made, they put the toilet like around her actual body. And so her head is in there. It&#39;s like a real head, you know, in the toilet, poor girl. And her eyes open in one of Catherine&#39;s hallucinations, because we get a little bit more of that. Because now Catherine runs up and hides in the attic. She&#39;s got the gun and she uses the jack in the box to lure Eric up. But it turns out that he&#39;s already there.</p>
                            <p>Dave (01:28:07.601)
                            Yeah.</p>
                            <p>Dave (01:28:17.051)
                            And then her eyes open.</p>
                            <p>Dave (01:28:33.435)
                            Oh, Catherine, he&#39;s already up there.</p>
                            <p>Bryan White (01:28:35.298)
                            Yeah, and she&#39;s she&#39;s struggling to remain conscious at this point because the drugs are really are really in her. She&#39;s still tripping balls, and this is when we see the head in the toilet again, and that&#39;s when Jeannie opens her eyes. And then. It turns out. It works, it&#39;s great.</p>
                            <p>Dave (01:28:50.23)
                            this part is actually pretty cool. Now you know it&#39;s coming.</p>
                            <p>Bryan White (01:28:55.382)
                            Oh, they set it up earlier, because the harlequin costume is set up when she finds it almost as though somebody&#39;s wearing it, but she touches it and nobody is. But now, Eric has already been up there. He&#39;s been up there the whole time that she&#39;s been trying to lure him. And they struggle, and he knocks the gun out of her hand, and she kind of goes reeling, and she finds a baby doll and rips the head off of it and finds the deadliest spike.</p>
                            <p>Bryan White (01:29:24.302)
                            that apparently was holding this baby doll&#39;s head on. Like this is a dangerous ass toy. God damn, that is some dangerous shit. Cause it is like, it&#39;s like an ice pick. She stabs him, stabs him a whole bunch of times. He goes reeling and he falls down the steps. And we see the, you know, eyes in the mask closed. Like presumably she has killed him, but it&#39;s a slasher movie. So, you know, and now she&#39;s overcome by the drugs and she passes out.</p>
                            <p>Dave (01:29:27.246)
                            I believe that is how they used to be made.</p>
                            <p>Dave (01:29:35.342)
                            And she stabs the shit out of him.</p>
                            <p>Bryan White (01:29:53.986)
                            at the top of the steps and we cut back to Eric&#39;s face in the mask and his eyes open, fade to black credits roll. I&#39;ve heard about one where she&#39;s in some sort of institution and he&#39;s pushing her in a wheelchair or something.</p>
                            <p>Dave (01:30:02.926)
                            Do you know about the alternate ending?</p>
                            <p>Dave (01:30:13.518)
                            So the way this was supposed to end was, you know, she stabs shit out of him, he falls down, eyes open, and then they cut away, and they cut back and it&#39;s the Harlequin costume floating in the pool. And the paramedics show up and the paramedics are like taking all the bodies out of the house and they drag the Harlequin body out of the pool and it turns out that it&#39;s Catherine in the costume in the pool and she&#39;s dead.</p>
                            <p>Dave (01:30:41.686)
                            And he said that he wanted he didn&#39;t want he didn&#39;t like that that horror movies had like always ended with the girl the final person living. It&#39;s like he wanted this to just everyone fucking salt the earth, but the distributor insisted that it end with a final.</p>
                            <p>Bryan White (01:30:41.99)
                            Huh.</p>
                            <p>Bryan White (01:30:56.094)
                            Okay, yeah, I gotta say I gotta go with them. I like, there are occasionally movies where, like horror movies that end on that down note where like the bad guy wins or something like that. I fucking love that. Like I think the Babadook ends that way where they, you know, they survive, but at what cost, they&#39;re now kind of like subservient to the spirit. That recent, that Mike Flanagan haunting of Hill House ends with the house winning.</p>
                            <p>Bryan White (01:31:24.058)
                            Like I love when that happens, not all the time, obviously.</p>
                            <p>Dave (01:31:25.962)
                            Well, that&#39;s how the original that&#39;s how house that&#39;s how the haunting of Hill House ends. That&#39;s how the book ends is like they just fucking leave because they</p>
                            <p>Bryan White (01:31:29.838)
                            Yeah, yeah, but like, but like, oh, but I didn&#39;t expect anybody to actually like, I didn&#39;t expect that basically. Like even though that&#39;s how Shirley Jackson intended it, they just, they don&#39;t fucking do that in movies and TV. Black, I love that, I love that about Black Christmas.</p>
                            <p>Dave (01:31:42.402)
                            That&#39;s also how black Christmas ends. It&#39;s like. Like, like, yeah, the cops show up, but the dude&#39;s still there.</p>
                            <p>Bryan White (01:31:50.274)
                            Yeah, yep. I love that ending. So overall, I think the movie itself is a bit of a mess, but</p>
                            <p>Dave (01:31:59.09)
                            Yeah, it&#39;s several ideas stitched together, and none of them are very clear.</p>
                            <p>Bryan White (01:32:03.35)
                            Um, but it works it works it it they clearly pulled the wool over my eyes with the the whole who done it thing like I honestly like there were times where I was like It&#39;s fucking peter the killer or something because he he comes and he goes and he shows up in like time That&#39;s the thing</p>
                            <p>Dave (01:32:18.286)
                            How could he be the killer? He&#39;s incompetent at A, he can&#39;t even go on a date.</p>
                            <p>Bryan White (01:32:23.134)
                            Oh, it could have been a whole like, I don&#39;t know, it could have been a blind thing, but like they, that was early on and maybe it&#39;s just because the inclusion of Peter as a character is just ineffable. I will never understand it. And so I&#39;m just trying to connect the fucking dots with this guy, but it&#39;s a great movie. I recommend it. I think it&#39;s fantastic. And it&#39;s also at a time when...</p>
                            <p>Dave (01:32:45.966)
                            fun watch.</p>
                            <p>Bryan White (01:32:48.21)
                            slasher movies like this was 82 so we were really starting to hit a terminal point where like everything that had really been done with the body count movie had really been done and so even yeah like but even at this point like this movie twists the formula in in just just enough for it to be very good very effective overall it&#39;s great</p>
                            <p>Dave (01:33:01.336)
                            Yeah, they&#39;re about to get into some real cheap crap.</p>
                            <p>Bryan White (01:33:17.05)
                            It was a good pick. I&#39;m glad you brought it to my attention. So what are we doing next week?</p>
                            <p>Dave (01:33:23.17)
                            Well, June is Pride Month and it is particularly important this year. So we are gonna watch some kind of queer movies. The first one is VAMP.</p>
                            <p>Bryan White (01:33:26.09)
                            Oh yes it is.</p>
                            <p>Bryan White (01:33:32.034)
                            Yep, weird. Uh huh, alright, I&#39;m into it. I&#39;m into it. Yep, the woke mind virus has gotten its its evil wicked tendrils in us. We have been. We&#39;ve been embraced by the woke mob, so yeah, we&#39;ll see you in two weeks where we go woke and do vamp.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/vamp">Next Episode</Link></li>
                        <li><Link to="/episodes/final-exam">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)